import { UnionToIntersection } from 'utility-types';

import { ReadingTypeColor } from 'consts/colors/colors';
import { TailwindColorGroup } from 'consts/colors/colors.types';
import { ReadingSampleType, ReadingType, Unit } from 'models';
import { ReadingCategory } from 'models/reading-category';
import { ReadingEntryType } from 'models/reading-entry-type';
import { DefinedRange, NullableRange } from 'types/utility';

/**
 * ReadingName enum is manually created using `{ReadingType}_{ReadingSampleType}`
 */
export enum ReadingName {
  GLUCOSE_BLOOD = 'glucose__blood',
  GLUCOSE_INTERSTITIAL = 'glucose__interstitial',

  KETONE_BLOOD = 'ketone__blood',
  KETONE_BREATH_ACE = 'ketone__breath_ace',
  KETONE_BREATH_PPM = 'ketone__breath_ppm',
  KETONE_URINE = 'ketone__urine',
  GKI_BLOOD = 'glucose_ketone_index__blood',
  DBR_BLOOD = 'dbr__blood',

  WEIGHT_MEASUREMENT = 'weight__measurement',
  WAIST_MEASUREMENT = 'waist__measurement',

  CARBS_INGESTION = 'carbohydrates__ingestion',
  FAT_INGESTION = 'fat__ingestion',
  PROTEIN_INGESTION = 'protein__ingestion',
  CALORIES_INGESTION = 'calories__ingestion',
  VITAMIN_D_INGESTION = 'vitamin_d__ingestion',
  MAGNESIUM_INGESTION = 'magnesium__ingestion',
  POTASSIUM_INGESTION = 'potassium__ingestion',
  OMEGA_3_INGESTION = 'omega_3__ingestion',
  OMEGA_3_EPA_INGESTION = 'omega_3_epa__ingestion',
  OMEGA_3_DHA_INGESTION = 'omega_3_dha__ingestion',
  ZINC_INGESTION = 'zinc__ingestion',
  VITAMIN_B12_INGESTION = 'vitamin_b12__ingestion',
  SELENIUM_INGESTION = 'selenium__ingestion',
  BMI_MEASUREMENT = 'bmi__measurement',
  HEART_RATE_MEASUREMENT = 'hr__measurement',
  HEART_RATE_VARIABILITY_MEASUREMENT = 'hrv__measurement',
  BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT = 'blood_pressure_systolic__measurement',
  BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT = 'blood_pressure_diastolic__measurement',

  SLEEP_SESSION_MEASUREMENT = 'sleep_session__measurement',

  // HOMA_IR_BLOOD = 'homa_ir__blood',

  INSULIN_SERUM_BLOOD = 'insulin_serum__blood',
  HDL_BLOOD = 'hdl__blood',
  LDL_BLOOD = 'ldl__blood',
  TRIGLYCERIDES_BLOOD = 'triglycerides__blood',
  HBA1C_BLOOD = 'hba1c__blood',
  APOB_BLOOD = 'apob__blood',
  APOAL_BLOOD = 'apoal__blood',
  T3_BLOOD = 't3__blood',
  FREE_T3_BLOOD = 'free_t3__blood',
  T4_BLOOD = 't4__blood',
  FREE_T4_BLOOD = 'free_t4__blood',
  TSH_BLOOD = 'tsh__blood',
  CARNITINE_BLOOD = 'carnitine__blood',

  VISCERAL_FAT_PERCENTAGE_MEASUREMENT = 'visceral_fat_percentage__measurement',
  ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT = 'absolute_fat_percentage__measurement',
  MUSCLE_MASS_PERCENTAGE_MEASUREMENT = 'muscle_mass_percentage__measurement',
  HSCRP_BLOOD = 'hscrp__blood',
  ALANINE_AMINOTRANSFERASE_BLOOD = 'alanine_aminotransferase__blood',
  ALBUMIN_BLOOD = 'albumin__blood',
  ALKALINE_PHOSPHATASE_BLOOD = 'alkaline_phosphatase__blood',
  ASPARTATE_AMINOTRANSFERASE_BLOOD = 'aspartate_aminotransferase__blood',
  BICARBONATE_BLOOD = 'bicarbonate__blood',
  BILIRUBIN_BLOOD = 'bilirubin__blood',
  BLOOD_UREA_NITROGEN_BLOOD = 'blood_urea_nitrogen__blood',
  CALCIUM_BLOOD = 'calcium__blood',
  CHLORIDE_BLOOD = 'chloride__blood',
  CREATININE_BLOOD = 'creatinine__blood',
  POTASSIUM_BLOOD = 'potassium__blood',
  SODIUM_BLOOD = 'sodium__blood',
  TOTAL_PROTEIN_BLOOD = 'total_protein__blood',
  COPPER_BLOOD = 'copper__blood',
  TNFA_BLOOD = 'tnfa__blood',
  IL6_BLOOD = 'il6__blood',
  BONE_MASS_MEASUREMENT = 'bone_mass__measurement',
  FAT_FREE_MASS_MEASUREMENT = 'fat_free_mass__measurement',
  HEIGHT_MEASUREMENT = 'height__measurement',
  VISCERAL_FAT_MEASUREMENT = 'visceral_fat__measurement',
  ABSOLUTE_FAT_MEASUREMENT = 'absolute_fat__measurement',
  MUSCLE_MASS_MEASUREMENT = 'muscle_mass__measurement',
  ACTIVITY_LEVEL_MEASUREMENT = 'activity_level__measurement',

  INSULIN_BASAL_MDI = 'insulin_basal__mdi',
  INSULIN_BOLUS_MDI = 'insulin_bolus__mdi',
}
export const readingNames = Object.values(ReadingName) as ReadingName[];

export const isReadingName = (value: any): value is ReadingName =>
  readingNames.includes(value);

export type GlucoseBloodUnit = Unit.MGDL | Unit.MMOLL;
export type GlucoseInterstitialUnit = Unit.MGDL | Unit.MMOLL;
export type KetoneBloodUnit = Unit.MMOLL;
export type KetoneUrineUnit = Unit.MMOLL;
export type KetoneBreathAceUnit = Unit.ACE;
export type KetoneBreathPpmUnit = Unit.PPM;
export type GkiBloodUnit = Unit.NO_UNIT;
export type DbrBloodUnit = Unit.NO_UNIT;
export type WeightMeasurementUnit = Unit.KG | Unit.LBS;
export type WaistUMeasurementUnit = Unit.CM | Unit.INCH;
export type CarbsIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type FatIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type ProteinIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type CaloriesIngestionUnit = Unit.KCAL;
export type VitaminDIngestionUnit = Unit.IU | Unit.MCG;
export type MagnesiumIngestionUnit = Unit.MG;
export type PotassiumIngestionUnit = Unit.MG;
export type Omega3IngestionUnit = Unit.MG;
export type Omega3EpaIngestionUnit = Unit.MG;
export type Omega3DhaIngestionUnit = Unit.MG;
export type ZincIngestionUnit = Unit.MG;
export type VitaminB12IngestionUnit = Unit.MCG;
export type SeleniumIngestionUnit = Unit.MCG;
export type BmiMeasurementUnit = Unit.KG_M2;
export type HeartRateMeasurementUnit = Unit.BPM;
export type HeartRateVariabilityMeasurementUnit = Unit.MS;
export type BloodPressureSystolicMeasurementUnit = Unit.MMHG;
export type BloodPressureDiastolicMeasurementUnit = Unit.MMHG;
export type SleepDurationUnit = Unit.S;

export type InsulinSerumBloodUnit = Unit.PMOLL | Unit.MCUL;
export type HdlBloodUnit = Unit.MMOLL | Unit.MGDL;
export type LdlBloodUnit = Unit.MMOLL | Unit.MGDL;
export type TriglyceridesBloodUnit = Unit.MMOLL | Unit.MGDL;
export type Hba1cBloodUnit = Unit.PERCENTAGE | Unit.MMOL_MOL;
export type ApobBloodUnit = Unit.MGDL | Unit.GL;
export type ApoalBloodUnit = Unit.MGDL | Unit.GL;
export type T3BloodUnit = Unit.NMOLL | Unit.NGDL;
export type FreeT3BloodUnit = Unit.PMOLL | Unit.PGML;
export type T4BloodUnit = Unit.NMOLL | Unit.MCGDL;
export type FreeT4BloodUnit = Unit.PMOLL | Unit.NGDL;
export type TshBloodUnit = Unit.MLUL | Unit.MCUML;
export type CarnitineBloodUnit = Unit.MCMOLL | Unit.MGDL;
export type VisceralFatPercentageMeasurementUnit = Unit.PERCENTAGE;
export type AbsoluteFatPercentageMeasurementUnit = Unit.PERCENTAGE;
export type MuscleMassPercentageMeasurementUnit = Unit.PERCENTAGE;
export type HscrpBloodUnit = Unit.MGL;
export type AlanineAminotransferaseBloodUnit = Unit.UL;
export type AlbuminBloodUnit = Unit.GDL;
export type AlkalinePhosphataseBloodUnit = Unit.UL;
export type AsparateAminotransferaseBloodUnit = Unit.UL;
export type BicarbonateBloodUnit = Unit.MEQL;
export type BilirubinBloodUnit = Unit.MGDL;
export type BloodUreaNitrogenBloodUnit = Unit.MGDL;
export type CalciumBloodUnit = Unit.MGDL;
export type ChlorideBloodUnit = Unit.MGDL;
export type CreatinineBloodUnit = Unit.MGDL;
export type PotassiumBloodUnit = Unit.MEQL;
export type SodiumBloodUnit = Unit.MEQL;
export type TotalProteinBloodUnit = Unit.GDL;
export type CopperBloodUnit = Unit.MCGDL;
export type TnfaBloodUnit = Unit.PGML;
export type Il6BloodUnit = Unit.PGML;
export type BoneMassMeasurementUnit = Unit.KG | Unit.LBS;
export type FatFreeMassMeasurementUnit = Unit.KG | Unit.LBS;
export type HeightMeasurementUnit = Unit.CM | Unit.INCH;
export type VisceralFatMeasurementUnit = Unit.KG | Unit.LBS;
export type AbsoluteFatMeasurementUnit = Unit.KG | Unit.LBS;
export type MuscleMassMeasurementUnit = Unit.KG | Unit.LBS;
export type ActivityLevelMeasurementUnit = Unit.NO_UNIT;

// export type HomaIrBloodUnit = Unit.MMOLL;
export type InsulinBasalMdiUnit = Unit.IU;
export type InsulinBolusMdiUnit = Unit.IU;

export type GkrReadingName = ReadingName.GKI_BLOOD | ReadingName.DBR_BLOOD;

export type ReadingNameToPreferredUnitMap = {
  [ReadingName.GLUCOSE_BLOOD]: GlucoseBloodUnit;
  [ReadingName.GLUCOSE_INTERSTITIAL]: GlucoseBloodUnit;
  [ReadingName.WEIGHT_MEASUREMENT]: WeightMeasurementUnit;
  [ReadingName.WAIST_MEASUREMENT]: WaistUMeasurementUnit;
  [ReadingName.PROTEIN_INGESTION]: ProteinIngestionUnit;
  [ReadingName.FAT_INGESTION]: FatIngestionUnit;
  [ReadingName.CARBS_INGESTION]: CarbsIngestionUnit;
  [ReadingName.VITAMIN_D_INGESTION]: VitaminDIngestionUnit;
};
export type ReadingNameWithPreferredUnit = keyof ReadingNameToPreferredUnitMap;
export type ReadingNameToNullablePreferredUnitMap = {
  [Key in ReadingNameWithPreferredUnit]:
    | ReadingNameToPreferredUnitMap[Key]
    | null;
};

export type ReadingNameToUnitMap = ReadingNameToPreferredUnitMap & {
  [ReadingName.KETONE_BLOOD]: KetoneBloodUnit;
  [ReadingName.KETONE_URINE]: KetoneUrineUnit;
  [ReadingName.KETONE_BREATH_ACE]: KetoneBreathAceUnit;
  [ReadingName.KETONE_BREATH_PPM]: KetoneBreathPpmUnit;
  [ReadingName.GKI_BLOOD]: GkiBloodUnit;
  [ReadingName.DBR_BLOOD]: DbrBloodUnit;
  [ReadingName.CALORIES_INGESTION]: CaloriesIngestionUnit;
  [ReadingName.MAGNESIUM_INGESTION]: MagnesiumIngestionUnit;
  [ReadingName.POTASSIUM_INGESTION]: PotassiumIngestionUnit;
  [ReadingName.OMEGA_3_INGESTION]: Omega3IngestionUnit;
  [ReadingName.OMEGA_3_EPA_INGESTION]: Omega3EpaIngestionUnit;
  [ReadingName.OMEGA_3_DHA_INGESTION]: Omega3DhaIngestionUnit;
  [ReadingName.ZINC_INGESTION]: ZincIngestionUnit;
  [ReadingName.VITAMIN_B12_INGESTION]: VitaminB12IngestionUnit;
  [ReadingName.SELENIUM_INGESTION]: SeleniumIngestionUnit;
  [ReadingName.BMI_MEASUREMENT]: BmiMeasurementUnit;
  [ReadingName.HEART_RATE_MEASUREMENT]: HeartRateMeasurementUnit;
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: HeartRateVariabilityMeasurementUnit;
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: BloodPressureSystolicMeasurementUnit;
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: BloodPressureDiastolicMeasurementUnit;

  [ReadingName.SLEEP_SESSION_MEASUREMENT]: SleepDurationUnit;
  // [ReadingName.SLEEP_AWAKE_DURATION]: SleepDurationUnit;
  // [ReadingName.SLEEP_DEEP_DURATION]: SleepDurationUnit;
  // [ReadingName.SLEEP_LIGHT_DURATION]: SleepDurationUnit;
  // [ReadingName.SLEEP_REM_DURATION]: SleepDurationUnit;

  [ReadingName.INSULIN_SERUM_BLOOD]: InsulinSerumBloodUnit;
  [ReadingName.HDL_BLOOD]: HdlBloodUnit;
  [ReadingName.LDL_BLOOD]: LdlBloodUnit;
  [ReadingName.TRIGLYCERIDES_BLOOD]: TriglyceridesBloodUnit;
  [ReadingName.HBA1C_BLOOD]: Hba1cBloodUnit;
  [ReadingName.APOB_BLOOD]: ApobBloodUnit;
  [ReadingName.APOAL_BLOOD]: ApoalBloodUnit;
  [ReadingName.T3_BLOOD]: T3BloodUnit;
  [ReadingName.FREE_T3_BLOOD]: FreeT3BloodUnit;
  [ReadingName.T4_BLOOD]: T4BloodUnit;
  [ReadingName.FREE_T4_BLOOD]: FreeT4BloodUnit;
  [ReadingName.TSH_BLOOD]: TshBloodUnit;
  [ReadingName.CARNITINE_BLOOD]: CarnitineBloodUnit;

  [ReadingName.VISCERAL_FAT_PERCENTAGE_MEASUREMENT]: VisceralFatPercentageMeasurementUnit;
  [ReadingName.ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT]: AbsoluteFatPercentageMeasurementUnit;
  [ReadingName.MUSCLE_MASS_PERCENTAGE_MEASUREMENT]: MuscleMassPercentageMeasurementUnit;
  [ReadingName.HSCRP_BLOOD]: HscrpBloodUnit;
  [ReadingName.ALANINE_AMINOTRANSFERASE_BLOOD]: AlanineAminotransferaseBloodUnit;
  [ReadingName.ALBUMIN_BLOOD]: AlbuminBloodUnit;
  [ReadingName.ALKALINE_PHOSPHATASE_BLOOD]: AlkalinePhosphataseBloodUnit;
  [ReadingName.ASPARTATE_AMINOTRANSFERASE_BLOOD]: AsparateAminotransferaseBloodUnit;
  [ReadingName.BICARBONATE_BLOOD]: BicarbonateBloodUnit;
  [ReadingName.BILIRUBIN_BLOOD]: BilirubinBloodUnit;
  [ReadingName.BLOOD_UREA_NITROGEN_BLOOD]: BloodUreaNitrogenBloodUnit;
  [ReadingName.CALCIUM_BLOOD]: CalciumBloodUnit;
  [ReadingName.CHLORIDE_BLOOD]: ChlorideBloodUnit;
  [ReadingName.CREATININE_BLOOD]: CreatinineBloodUnit;
  [ReadingName.POTASSIUM_BLOOD]: PotassiumBloodUnit;
  [ReadingName.SODIUM_BLOOD]: SodiumBloodUnit;
  [ReadingName.TOTAL_PROTEIN_BLOOD]: TotalProteinBloodUnit;
  [ReadingName.COPPER_BLOOD]: CopperBloodUnit;
  [ReadingName.TNFA_BLOOD]: TnfaBloodUnit;
  [ReadingName.IL6_BLOOD]: Il6BloodUnit;
  [ReadingName.BONE_MASS_MEASUREMENT]: BoneMassMeasurementUnit;
  [ReadingName.FAT_FREE_MASS_MEASUREMENT]: FatFreeMassMeasurementUnit;
  [ReadingName.HEIGHT_MEASUREMENT]: HeightMeasurementUnit;
  [ReadingName.VISCERAL_FAT_MEASUREMENT]: VisceralFatMeasurementUnit;
  [ReadingName.ABSOLUTE_FAT_MEASUREMENT]: AbsoluteFatMeasurementUnit;
  [ReadingName.MUSCLE_MASS_MEASUREMENT]: MuscleMassMeasurementUnit;
  [ReadingName.ACTIVITY_LEVEL_MEASUREMENT]: ActivityLevelMeasurementUnit;

  // [ReadingName.HOMA_IR_BLOOD]: HomaIrBloodUnit;
  [ReadingName.INSULIN_BASAL_MDI]: InsulinBasalMdiUnit;
  [ReadingName.INSULIN_BOLUS_MDI]: InsulinBolusMdiUnit;
};

type ReadingNameToReadingTypeMap = {
  [ReadingName.GLUCOSE_BLOOD]: ReadingType.GLUCOSE;
  [ReadingName.GLUCOSE_INTERSTITIAL]: ReadingType.GLUCOSE;
  [ReadingName.WEIGHT_MEASUREMENT]: ReadingType.WEIGHT;
  [ReadingName.WAIST_MEASUREMENT]: ReadingType.WAIST;
  [ReadingName.PROTEIN_INGESTION]: ReadingType.PROTEIN;
  [ReadingName.FAT_INGESTION]: ReadingType.FAT;
  [ReadingName.CARBS_INGESTION]: ReadingType.CARBS;
  [ReadingName.VITAMIN_D_INGESTION]: ReadingType.VITAMIN_D;
  [ReadingName.KETONE_BLOOD]: ReadingType.KETONE;
  [ReadingName.KETONE_BLOOD]: ReadingType.KETONE;
  [ReadingName.KETONE_URINE]: ReadingType.KETONE;
  [ReadingName.KETONE_BREATH_ACE]: ReadingType.KETONE;
  [ReadingName.KETONE_BREATH_PPM]: ReadingType.KETONE;
  [ReadingName.GKI_BLOOD]: ReadingType.GLUCOSE_KETONE_INDEX;
  [ReadingName.DBR_BLOOD]: ReadingType.DBR;
  [ReadingName.CALORIES_INGESTION]: ReadingType.CALORIES;
  [ReadingName.MAGNESIUM_INGESTION]: ReadingType.MAGNESIUM;
  [ReadingName.POTASSIUM_INGESTION]: ReadingType.POTASSIUM;
  [ReadingName.OMEGA_3_INGESTION]: ReadingType.OMEGA_3;
  [ReadingName.OMEGA_3_EPA_INGESTION]: ReadingType.OMEGA_3_EPA;
  [ReadingName.OMEGA_3_DHA_INGESTION]: ReadingType.OMEGA_3_DHA;
  [ReadingName.ZINC_INGESTION]: ReadingType.ZINC;
  [ReadingName.VITAMIN_B12_INGESTION]: ReadingType.VITAMIN_B12;
  [ReadingName.SELENIUM_INGESTION]: ReadingType.SELENIUM;
  [ReadingName.BMI_MEASUREMENT]: ReadingType.BMI;
  [ReadingName.HEART_RATE_MEASUREMENT]: ReadingType.HEART_RATE;
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: ReadingType.HEART_RATE_VARIABILITY;
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: ReadingType.BLOOD_PRESSURE_SYSTOLIC;
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: ReadingType.BLOOD_PRESSURE_DIASTOLIC;

  [ReadingName.SLEEP_SESSION_MEASUREMENT]: ReadingType.SLEEP_SESSION;
  // [ReadingName.SLEEP_AWAKE_DURATION]: ReadingType.SLEEP_AWAKE;
  // [ReadingName.SLEEP_REM_DURATION]: ReadingType.SLEEP_REM;
  // [ReadingName.SLEEP_DEEP_DURATION]: ReadingType.SLEEP_DEEP;
  // [ReadingName.SLEEP_LIGHT_DURATION]: ReadingType.SLEEP_LIGHT;

  [ReadingName.INSULIN_SERUM_BLOOD]: ReadingType.INSULIN_SERUM;
  [ReadingName.HDL_BLOOD]: ReadingType.HDL;
  [ReadingName.LDL_BLOOD]: ReadingType.LDL;
  [ReadingName.TRIGLYCERIDES_BLOOD]: ReadingType.TRIGLYCERIDES;
  [ReadingName.HBA1C_BLOOD]: ReadingType.HBA1C;
  [ReadingName.APOB_BLOOD]: ReadingType.APOB;
  [ReadingName.APOAL_BLOOD]: ReadingType.APOAL;
  [ReadingName.T3_BLOOD]: ReadingType.T3;
  [ReadingName.FREE_T3_BLOOD]: ReadingType.FREE_T3;
  [ReadingName.T4_BLOOD]: ReadingType.T4;
  [ReadingName.FREE_T4_BLOOD]: ReadingType.FREE_T4;
  [ReadingName.TSH_BLOOD]: ReadingType.TSH;
  [ReadingName.CARNITINE_BLOOD]: ReadingType.CARNITINE;

  [ReadingName.VISCERAL_FAT_PERCENTAGE_MEASUREMENT]: ReadingType.VISCERAL_FAT_PERCENTAGE;
  [ReadingName.ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT]: ReadingType.ABSOLUTE_FAT_PERCENTAGE;
  [ReadingName.MUSCLE_MASS_PERCENTAGE_MEASUREMENT]: ReadingType.MUSCLE_MASS_PERCENTAGE;
  [ReadingName.HSCRP_BLOOD]: ReadingType.HSCRP;
  [ReadingName.ALANINE_AMINOTRANSFERASE_BLOOD]: ReadingType.ALANINE_AMINOTRANSFERASE;
  [ReadingName.ALBUMIN_BLOOD]: ReadingType.ALBUMIN;
  [ReadingName.ALKALINE_PHOSPHATASE_BLOOD]: ReadingType.ALKALINE_PHOSPHATASE;
  [ReadingName.ASPARTATE_AMINOTRANSFERASE_BLOOD]: ReadingType.ASPARTATE_AMINOTRANSFERASE;
  [ReadingName.BICARBONATE_BLOOD]: ReadingType.BICARBONATE;
  [ReadingName.BILIRUBIN_BLOOD]: ReadingType.BILIRUBIN;
  [ReadingName.BLOOD_UREA_NITROGEN_BLOOD]: ReadingType.BLOOD_UREA_NITROGEN;
  [ReadingName.CALCIUM_BLOOD]: ReadingType.CALCIUM;
  [ReadingName.CHLORIDE_BLOOD]: ReadingType.CHLORIDE;
  [ReadingName.CREATININE_BLOOD]: ReadingType.CREATININE;
  [ReadingName.POTASSIUM_BLOOD]: ReadingType.POTASSIUM;
  [ReadingName.SODIUM_BLOOD]: ReadingType.SODIUM;
  [ReadingName.TOTAL_PROTEIN_BLOOD]: ReadingType.TOTAL_PROTEIN;
  [ReadingName.COPPER_BLOOD]: ReadingType.COPPER;
  [ReadingName.TNFA_BLOOD]: ReadingType.TNFA;
  [ReadingName.IL6_BLOOD]: ReadingType.IL6;
  [ReadingName.BONE_MASS_MEASUREMENT]: ReadingType.BONE_MASS;
  [ReadingName.FAT_FREE_MASS_MEASUREMENT]: ReadingType.FAT_FREE_MASS;
  [ReadingName.HEIGHT_MEASUREMENT]: ReadingType.HEIGHT;
  [ReadingName.VISCERAL_FAT_MEASUREMENT]: ReadingType.VISCERAL_FAT;
  [ReadingName.ABSOLUTE_FAT_MEASUREMENT]: ReadingType.ABSOLUTE_FAT;
  [ReadingName.MUSCLE_MASS_MEASUREMENT]: ReadingType.MUSCLE_MASS;
  [ReadingName.ACTIVITY_LEVEL_MEASUREMENT]: ReadingType.ACTIVITY_LEVEL;

  // [ReadingName.HOMA_IR_BLOOD]: ReadingType.HOMA_IR;
  [ReadingName.INSULIN_BASAL_MDI]: ReadingType.INSULIN_BASAL;
  [ReadingName.INSULIN_BOLUS_MDI]: ReadingType.INSULIN_BOLUS;
};

export type UnitForReadingName<T extends ReadingName> = ReadingNameToUnitMap[T];

type Range = NullableRange | DefinedRange;
export type ReadingRanges<T extends ReadingName, TRange extends Range> = Record<
  ReadingNameToUnitMap[T],
  TRange
>;
export type DefinedReadingRanges<T extends ReadingName> = ReadingRanges<
  T,
  DefinedRange
>;
export type RangesPerReadingName = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, Range>;
};
export type DefinedRangesPerReadingName = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, DefinedRange>;
};
export type NullableRangesMap = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, NullableRange>;
};

type ReadingNameRangeColorPallete = {
  belowLowerTresholdRange: string;
  withinLowerTresholdRange: string;
  inRange: string;
  withinUpperTresholdRange: string;
  aboveUpperTresholdRange: string;
};

export type DomainConfig<T extends ReadingName = ReadingName> = {
  readingName: T;
  readingType: ReadingNameToReadingTypeMap[T];
  readingSampleType: ReadingSampleType;
  category: ReadingCategory;
  entryTypes: NonEmptyArray<ReadingEntryType>;
  color: ReadingTypeColor;
  colorPalette: TailwindColorGroup;
  colorPaletteDark: TailwindColorGroup;
  units: NonEmptyArray<UnitForReadingName<T>>;
  defaultRanges: DefinedReadingRanges<T>;
  boundaryRanges: DefinedReadingRanges<T>;
  rangeColorPalette: ReadingNameRangeColorPallete;
  label: () => string;
  labelIncludesUnit?: boolean;

  /**
   * Used to access Reading Details page
   */
  slug: string;

  /**
   * Should it be possible to manually add a reading
   */
  canBeAddedManually: boolean;
};

export type DomainConfigs = {
  [T in ReadingName]: DomainConfig<T>;
};

type DomainConfigRangesUnion = DomainConfigs[ReadingName]['defaultRanges'];
export type DomainConfigRangesIntersection =
  UnionToIntersection<DomainConfigRangesUnion>;

export enum KetoneUrineValueType {
  NEGATIVE = 'negative',
  TRACE = 'trace',
  SMALL = 'small',
  MODERATE = 'moderate',
  LARGE = 'large',
  LARGER = 'larger',
}

type ContinuousReadingType =
  | ReadingType.HEART_RATE
  | ReadingType.HEART_RATE_VARIABILITY;
type ContinuousReadingName =
  | ReadingName.HEART_RATE_MEASUREMENT
  | ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT;

export type ReadingTypeWithoutContinuousEntryType = Exclude<
  ReadingType,
  ContinuousReadingType
>;

export type ReadingNameWithoutContinuousEntryType = Exclude<
  ReadingName,
  ContinuousReadingName
>;

export enum CustomReadingName {
  MACROS = 'macros',
  BLOOD_PRESSURE = 'blood-pressure',
}

export type MacroReadingName =
  | ReadingName.FAT_INGESTION
  | ReadingName.CARBS_INGESTION
  | ReadingName.PROTEIN_INGESTION;

const customReadingNames = Object.values(CustomReadingName);

/**
 * Type used in lists of readings, where aside of standard reading names, there
 * need to be displayed other custom items.
 */
export type ReadingItem = `${ReadingName}` | `${CustomReadingName}`;

/**
 * Config used to display reading names lists
 */
export type ReadingItemConfig = {
  readingItem: ReadingItem;
  slug: string;
  category: ReadingCategory;
  label: () => string;
  canBeAddedManually: boolean;
};

export const isReadingItem = (value: any): value is ReadingItem =>
  [...readingNames, ...customReadingNames].includes(value);
