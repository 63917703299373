import { UrlQueryParams } from 'shared/common/consts';
import { routes } from 'shared/common/routes';

export const getLoginRedirectionUrl = ({
  codeChallenge,
  scope,
  oauthClientId,
  oauthBaseUrl,
  isCurrentRegion,
  state,
}: {
  codeChallenge: string;
  scope: string;
  oauthClientId: string;
  oauthBaseUrl: string;
  isCurrentRegion: boolean;
  state?: string | null;
}): string => {
  const skipRegionSelectScreen = isCurrentRegion;

  // if there is prompt param present in url we should take it as a redirection prompt value
  const promptParam = new URLSearchParams(window.location.search).get(
    UrlQueryParams.PROMPT
  );

  const params = {
    response_type: 'code',
    client_id: oauthClientId,
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    scope,
    redirect_uri: getOauthRedirectUri(),

    // - `0` - redirects to login screen
    // - `1` - redirects to region select screen
    region: skipRegionSelectScreen ? 0 : 1,
    prompt: promptParam || 'login',
    ...(state && { state }),
  };

  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([param, value]) =>
    urlSearchParams.append(param, String(value))
  );

  return `${oauthBaseUrl}/oauth/authorize?${urlSearchParams.toString()}`;
};

const getAbsoluteUrl = (route: string): string => {
  const url = new URL(route, window.location.origin);
  return url.href;
};

export const getOauthRedirectUri = (): string =>
  getAbsoluteUrl(routes.oauthLanding);
