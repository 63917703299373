import { apiHttpClient as http } from 'shared/common/api';
import { HttpHeaders } from 'shared/common/consts';
import { practiceApiRoutes } from 'shared/practice/consts';
import {
  PracticeDto,
  PublicPracticeDto,
  PublicPracticesRequestParams,
} from 'shared/practice/types';
import { ReadingsParams } from 'shared/reading/types';
import { DeepPartial } from 'utility-types';

import { practiceAffiliateLinkApiFactory } from './practice-affiliate-link-api';
import { practiceRangeDescriptionApiFactory } from './practice-range-description-api';

export const practiceApiFactory = (httpClient: typeof http) => ({
  getPractices: async (params: { getMyParentPractices?: boolean } = {}) => {
    const res = await httpClient<PracticeDto[]>(practiceApiRoutes.practices, {
      method: 'GET',
      params,
    });
    return res.data;
  },

  getPublicPractices: async (params: PublicPracticesRequestParams = {}) => {
    const res = await httpClient<PublicPracticeDto[]>(
      practiceApiRoutes.publicPractices,
      {
        method: 'GET',
        params,
      }
    );

    return {
      data: res.data,
      totalCount: Number(res.headers[HttpHeaders.TOTAL_COUNT]),
    };
  },

  getPractice: async (
    practiceId: string,
    params: { withTags?: boolean } = {}
  ) => {
    const res = await httpClient<PracticeDto>(practiceApiRoutes.practice, {
      method: 'GET',
      pathParams: { practiceId },
      params,
    });
    return res.data;
  },

  createPractice: async ({ data }: { data: Partial<PracticeDto> }) => {
    const res = await httpClient<PracticeDto>(practiceApiRoutes.practices, {
      method: 'POST',
      data,
    });

    return res.data;
  },

  updatePractice: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: DeepPartial<PracticeDto>;
  }) => {
    const res = await httpClient(practiceApiRoutes.practice, {
      method: 'PATCH',
      pathParams: { practiceId },
      data,
    });

    return res.data;
  },

  updatePracticeLogo: async ({
    practiceId,
    data,
  }: {
    practiceId: string;
    data: FormData;
  }) => {
    const res = await httpClient(practiceApiRoutes.practiceLogo, {
      method: 'PATCH',
      pathParams: { practiceId },
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  },

  removePractice: async ({ practiceId }: { practiceId: string }) => {
    const res = await httpClient(practiceApiRoutes.practice, {
      method: 'DELETE',
      pathParams: { practiceId },
    });

    return res.data;
  },

  exportPatientsData: async ({
    practiceId,
    params,
  }: {
    practiceId: string;
    params: Partial<
      ReadingsParams & {
        includeTags?: boolean;
        personalDetails?: boolean;
      }
    >;
  }) => {
    const res = await httpClient(practiceApiRoutes.exportPatientsData, {
      method: 'GET',
      pathParams: { practiceId },
      params,
    });

    return res.data;
  },
  ...practiceAffiliateLinkApiFactory(httpClient),
  ...practiceRangeDescriptionApiFactory(httpClient),
});
