import { PracticeRole } from 'shared/common/consts';
import {
  NullableRanges,
  PreferredUnits,
  PreferredUnitsDto,
  RangesDto,
} from 'shared/common/types';

import { GkrType } from 'models/gkr-type';

export interface PracticeDto extends PreferredUnitsDto, RangesDto {
  ehr_practice_id: string;
  ehr_practice_name: string;
  ehr_practice_canonical_name: string;
  ehr_practice_description: Nullable<string>;
  ehr_practice_specialties: Nullable<string[]>;
  ehr_practice_contact_info: Nullable<string>;
  ehr_system: {
    can_search_patients: boolean;
    ehr_name: string;
    ehr_canonical_name: string;
    ehr_partner_system: Nullable<ExternalEhrPartner>;
    is_sending_reports_enabled: boolean;
  };
  report_type: ReportType;
  report_length: ReportLength;
  report_schedule: Nullable<{
    frequency: Nullable<PracticeReportScheduleFrequency>;
    day_number: number;
    enabled: boolean;
    real_time_reports: boolean;
  }>;
  patient_signup_enabled: boolean;
  hcp_signup_enabled: boolean;
  listed_in_hcpd_enabled: boolean;
  patients_tags?: Nullable<string[]>;
  created_at: string;
  updated_at: string;
  glucose_ketone_ratio_type: GkrType | null;
  is_chat_enabled: boolean;
  patients_count: number;
  ehr_practice_type: PracticeType;
  enabled_viewing_other_users_readings: boolean;
  practice_roles: PracticeRole[];
  hcp_signup_url: Nullable<string>;
  patient_signup_url: Nullable<string>;
  qualifying_criteria: Nullable<string>;
  logo_image_url: Nullable<string>;
  address_country: Nullable<string>;
  address_state: Nullable<string>;
  address_town: Nullable<string>;
  address_street: Nullable<string>;
  address_street_number: Nullable<string>;
  address_zip_code: Nullable<string>;
  contact_email: Nullable<string>;
  contact_website: Nullable<string>;
  contact_phone: Nullable<string>;
  languages: string[];
  practice_owner: {
    user_id: string;
    first_name: string;
    last_name: string;
  };
  target_total_daily_proteins: Nullable<number>;
  target_total_daily_fats: Nullable<number>;
}

/**
 * Model used for practice directory
 */
export type PublicPracticeDto = Pick<
  PracticeDto,
  | 'ehr_practice_id'
  | 'ehr_practice_name'
  | 'ehr_practice_description'
  | 'qualifying_criteria'
  | 'ehr_practice_type'
  | 'ehr_practice_specialties'
  | 'enabled_viewing_other_users_readings'
  | 'patient_signup_enabled'
  | 'hcp_signup_enabled'
  | 'created_at'
  | 'hcp_signup_url'
  | 'patient_signup_url'
  | 'logo_image_url'
  | 'address_country'
  | 'address_state'
  | 'address_town'
  | 'address_street'
  | 'address_street_number'
  | 'address_zip_code'
  | 'contact_email'
  | 'contact_website'
  | 'contact_phone'
  | 'languages'
  | 'practice_owner'
  | 'ehr_practice_contact_info'
>;

export type ExternalEhrPartner = 'elation' | 'epic' | 'athenahealth' | 'cerbo';

export interface Practice {
  id: string;
  name: string;
  slug: string;
  ehrSystem: {
    ehrName: string;
    ehrCanonicalName: string;
    ehrPartnerSystem: Nullable<ExternalEhrPartner>;
    canSearchPatients: boolean;
    isSendingReportsEnabled: boolean;
  };
  isExternalEhrConnected: boolean;
  reportType: ReportType;
  reportLength: ReportLength;
  reportSchedule?: {
    frequency?: PracticeReportScheduleFrequency;
    dayNumber?: number;
    enabled?: boolean;
    realTimeReports?: boolean;
  };
  preferredUnits: PreferredUnits;
  ranges: NullableRanges;
  description: string;
  contactInfo: string;
  specialties: string[];
  patientSignupEnabled: boolean;
  hcpSignupEnabled: boolean;
  listedInHcpdEnabled: boolean;
  patientsTags: string[];
  gkrType: GkrType | null;
  createdAt: string;
  updatedAt: string;
  isChatEnabled: boolean;
  patientsCount: number;
  practiceType: PracticeType;
  enabledViewingOtherPatientsReadings: boolean;
  practiceRoles: PracticeRole[];
  hcpSignupUrl: Nullable<string>;
  patientSignupUrl: Nullable<string>;
  qualifyingCriteria: string;
  logoImageUrl: Nullable<string>;
  country: string;
  state: string;
  town: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  email: string;
  website: string;
  phone: string;
  languages: string[];
  practiceOwner: Nullable<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
}

/**
 * Model used for practice directory
 */
export type PublicPractice = Pick<
  Practice,
  | 'id'
  | 'name'
  | 'description'
  | 'qualifyingCriteria'
  | 'practiceType'
  | 'specialties'
  | 'enabledViewingOtherPatientsReadings'
  | 'patientSignupEnabled'
  | 'hcpSignupEnabled'
  | 'createdAt'
  | 'hcpSignupUrl'
  | 'patientSignupUrl'
  | 'logoImageUrl'
  | 'country'
  | 'state'
  | 'town'
  | 'street'
  | 'streetNumber'
  | 'zipCode'
  | 'email'
  | 'website'
  | 'phone'
  | 'languages'
  | 'practiceOwner'
>;

export enum ReportType {
  ALL = 'all',
  DAILY_MIN_MAX = 'daily_min_max',
  WEEKLY_MIN_MAX = 'weekly_min_max',
  MONTHLY_MIN_MAX = 'monthly_min_max',
}

export enum ReportLength {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  YEARLY = 'yearly',
}

export enum PracticeType {
  PRACTICE = 'practice',
  SUPPORT_GROUP = 'support_group',
  COACHING_GROUP = 'coaching_group',
  // used only internally when referring to multiple practices
  GROUP = 'group',
}

export enum PracticeReportScheduleFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
