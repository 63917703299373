import { round } from 'lodash';

import {
  HeightMeasurementUnit,
  WeightMeasurementUnit,
} from 'domain/domain.models';
import { Unit } from 'models';

import { bodyFatLevels, calculatorConsts, genders } from '../config/defaults';
import { ActivityLevel, FatData, Gender } from '../types';

export const convertInchesToCm = (inches: number) =>
  round(inches * calculatorConsts.convertInchesToCm);

export const convertCmToInches = (cm: number) =>
  round(cm / calculatorConsts.convertInchesToCm);

export const convertInchesToFeet = (inches: number) => {
  const feet = Math.floor(inches / calculatorConsts.convertFeetToInches);

  return {
    feet,
    inches: Math.floor(inches - feet * 12),
  };
};

export const convertCmToM = (cm: number) => cm / 100;

export const convertMToCm = (m: number) => m * 100;

export const calculateHeightMeasures = (
  height: number,
  unit: HeightMeasurementUnit
) => {
  if (unit === 'cm') {
    const { inches, feet } = convertInchesToFeet(convertCmToInches(height));

    return {
      cm: round(height),
      inches,
      feet,
    };
  } else {
    const { inches, feet } = convertInchesToFeet(height);

    return {
      cm: convertInchesToCm(height),
      inches,
      feet,
    };
  }
};

export const convertKgToLbs = (kg: number) =>
  round(kg * calculatorConsts.convertKgToLbs, 0);

export const convertLbsToKg = (lbs: number) =>
  round(lbs / calculatorConsts.convertKgToLbs, 0);

export const calculateWeight = (weight: number, unit: WeightMeasurementUnit) =>
  unit === Unit.KG
    ? {
        kg: weight,
        lbs: convertKgToLbs(weight),
      }
    : {
        kg: convertLbsToKg(weight),
        lbs: weight,
      };

// Body Mass Index
export const calculateBmi = (heightInCm: number, weightInKg: number) =>
  round(weightInKg / (heightInCm / 100) ** 2, 1);

export const calculateBmrCalories = (
  gender: Gender,
  weightInKg: number,
  heightInCm: number,
  age: number
): number => {
  if (gender === genders.M) {
    // BMR(metric) for male = (10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) + 5
    return round(
      calculatorConsts.weightBmr * weightInKg +
        calculatorConsts.heightBmr * heightInCm -
        calculatorConsts.ageBmr * age +
        calculatorConsts.bmrForMale,
      0
    );
  }

  // BMR (metric) for female  = (10 × weight in kg) + (6.25 × height in cm) - (5 × age in years) - 161
  return round(
    calculatorConsts.weightBmr * weightInKg +
      calculatorConsts.heightBmr * heightInCm -
      calculatorConsts.ageBmr * age -
      calculatorConsts.bmrForFemale,
    0
  );
};

/** kcal daily energy expenditure (activity lavel) */
export const calculateBmrAccordingActivity = (
  bmr: number,
  activityLevelValue: ActivityLevel
) => round(+bmr * activityLevelValue, 2);

export type CalculateProteinProps = {
  weight: number;
  weightUnit: WeightMeasurementUnit;
  accordingActivity: number;
};

export const calculateProtein = ({
  weight,
  weightUnit,
  accordingActivity,
}: CalculateProteinProps) => {
  const min = calculateProteinMin(accordingActivity);
  const max = calculateProteinMax(accordingActivity);
  const suggested = calculateProteinSuggested(accordingActivity);

  const { gPerKg: max_g_per_kg, gPerLbs: max_g_per_lb } = calculatePerUnit(
    max,
    weight,
    weightUnit
  );

  const { gPerKg: min_g_per_kg, gPerLbs: min_g_per_lb } = calculatePerUnit(
    min,
    weight,
    weightUnit
  );

  const { gPerKg: chosen_g_per_kg, gPerLbs: chosen_g_per_lb } =
    calculatePerUnit(suggested, weight, weightUnit);

  return {
    min,
    max,
    suggested,
    min_g_per_kg,
    max_g_per_kg,
    min_g_per_lb,
    max_g_per_lb,
    chosen_g_per_kg,
    chosen_g_per_lb,
    chosen: suggested,
  };
};

export const calculateFat = (
  accordingActivity: number
): Omit<FatData, 'chosen'> => {
  const { kcal, g } = calculateFatMaintenance(accordingActivity);

  // Deficit -30%
  const minKcal = round((kcal * 70) / 100, 0);
  const minG = round((g * 70) / 100, 0);

  // Surplus 30%
  const maxKcal = round((kcal * 130) / 100, 0);
  const maxG = round((g * 130) / 100);

  // Deficit -20%
  const chosenKcal = round((kcal * 80) / 100, 0);
  const chosenG = round((g * 80) / 100, 0);

  return {
    chosen_g: chosenG,
    chosen_kcal: chosenKcal,

    min_kcal: minKcal,
    min_g: minG,

    maintenance_g: g,
    maintenance_kcal: kcal,

    max_kcal: maxKcal,
    max_g: maxG,
  };
};

// formula
// Body Fat Index
// men: (1.20 x BMI) + (0.23 x Age) – 16.2,
// women: (1.20 x BMI) + (0.23 x Age) – 5.4
export const calculateBfi = (
  gender: Gender,
  heightCm: number,
  weightInKg: number,
  age: number
) => {
  const genderIndex =
    gender === genders.M
      ? calculatorConsts.bfiMale
      : calculatorConsts.bfiFemale;

  return round(
    1.2 * calculateBmi(heightCm, weightInKg) + 0.23 * age - genderIndex,
    2
  );
};

export const calculateFatG = (kcal: number, minIntakeFatG: number) =>
  Math.round((kcal * minIntakeFatG) / 100 / 9);

export const calculateProteinMin = (accordingActivity: number) => {
  const proteinMin =
    (accordingActivity * Math.abs(calculatorConsts.settings.fat_min)) / 100;
  const calculatedProteinMin = Math.round(accordingActivity - proteinMin);
  const minProtein = Math.round(
    (calculatedProteinMin * calculatorConsts.settings.protein_min) / 100 / 4
  );

  return minProtein;
};

export const calculateProteinMax = (accordingActivity: number) =>
  round(calculateProteinMin(accordingActivity) / 0.7);

export const calculateProteinSuggested = (accordingActivity: number) =>
  round((calculateProteinMin(accordingActivity) * 0.8) / 0.7);

export const calculatePerUnit = (
  quantity: number,
  weight: number,
  weightUnit: WeightMeasurementUnit
) => {
  const { kg, lbs } = calculateWeight(weight, weightUnit);

  return {
    gPerKg: round(quantity / kg, 2),
    gPerLbs: round(quantity / lbs, 2),
  };
};

export const calculateFatMaintenance = (accordingActivity: number) => {
  const kcal = Math.round(accordingActivity);
  const g = Math.round(
    (Math.round(accordingActivity) * calculatorConsts.settings.fat_max_g) /
      100 /
      9
  );

  return {
    kcal,
    g,
  };
};

type GetBfiData =
  | {
      value: string;
      min?: number;
      max?: number;
    }
  | {
      value?: string;
      min: number;
      max: number | null;
    };

export const getBfiData = ({ value, min, max }: GetBfiData) => {
  if (value != null) {
    // set min, max when value is provided as 'range' or 'greater than'
    if (value.includes('-')) {
      const bfi = value.split('-');
      min = +bfi[0];
      max = +bfi[1];
    } else if (value.includes('>')) {
      const bfi = value.split('>');
      min = +bfi[1];
      max = null;
    } else {
      min = +value;
      max = +value;
    }
  } else {
    // set value when min or max provided
    if (min === max) {
      value = min?.toString();
    } else if (max) {
      value = `${min}-${max}`;
    } else {
      value = `>${min}`;
    }
  }

  const bodyFatLevelItem = Object.values(bodyFatLevels).find(
    (lvl) => lvl.valueMan === value || lvl.valueWoman === value
  );

  return {
    value,
    min: round(min!),
    max: round(max!),
    label: `${bodyFatLevelItem?.label ? bodyFatLevelItem.label : 'custom'}`,
  };
};

export const calculateDeficit = (maintenanceG: number, currentG: number) =>
  round((currentG * 100) / maintenanceG, 0) - 100;

export const calculateChosenKCalFromFatMaintenance = (
  chosenDeficit: number,
  maintenanceKcal: number
) => round(((100 + chosenDeficit) * maintenanceKcal) / 100, 0);

export const calculateCalories = ({
  fatG,
  proteinG,
  carbsG,
}: {
  fatG?: number;
  proteinG?: number;
  carbsG?: number;
}) => ({
  ...(fatG
    ? {
        fatKcal: fatG * 9,
      }
    : {}),
  ...(proteinG
    ? {
        proteinKcal: proteinG * 4,
      }
    : {}),
  ...(carbsG
    ? {
        carbsKcal: carbsG * 4,
      }
    : {}),
});

export const calculateFatFromMinMax = (min: number, max: number) =>
  (max + min) / 2;
