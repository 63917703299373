const joinOldDashboardUrl =
  (oldDashboardUrl: string) =>
  (path: string): string =>
    new URL(path, oldDashboardUrl).href;

export const getOldDashboardRoutes = (oldDashboardUrl: string) => {
  const concatDashboardUrl = joinOldDashboardUrl(oldDashboardUrl);

  return {
    home: () => concatDashboardUrl('dashboard'),
    adminPanel: () => concatDashboardUrl('admin'),
    apiPartnerSettings: () => concatDashboardUrl('partner'),
    register: () => concatDashboardUrl('register'),
    hcpRegister: () => concatDashboardUrl('hcp/register/'),
    twoFactorAuthSetup: () => concatDashboardUrl('user/account/2fa/setup'),
    logout: () => concatDashboardUrl('logout'),
    passkeys: () => concatDashboardUrl('passkeys'),
  };
};
