import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

import { NullableRanges, RangesDto } from '../../types/ranges';

export const mapToRanges = (dto: RangesDto): NullableRanges => ({
  [ReadingName.GKI_BLOOD]: {
    [Unit.NO_UNIT]: [dto.gki_min, dto.gki_max],
  },
  [ReadingName.DBR_BLOOD]: {
    [Unit.NO_UNIT]: [dto.dbr_min, dto.dbr_max],
  },
  [ReadingName.KETONE_BLOOD]: {
    [Unit.MMOLL]: [dto.ketones_min, dto.ketones_max],
  },
  [ReadingName.KETONE_BREATH_ACE]: {
    [Unit.ACE]: [dto.ketones_breath_ace_min, dto.ketones_breath_ace_max],
  },
  [ReadingName.KETONE_BREATH_PPM]: {
    [Unit.PPM]: [dto.ketones_breath_ppm_min, dto.ketones_breath_ppm_max],
  },
  [ReadingName.KETONE_URINE]: {
    [Unit.MMOLL]: [dto.ketones_urine_mmoll_min, dto.ketones_urine_mmoll_max],
  },
  [ReadingName.GLUCOSE_BLOOD]: {
    [Unit.MMOLL]: [dto.glucose_mmoll_min, dto.glucose_mmoll_max],
    [Unit.MGDL]: [dto.glucose_mgdl_min, dto.glucose_mgdl_max],
  },
  [ReadingName.GLUCOSE_INTERSTITIAL]: {
    [Unit.MMOLL]: [dto.glucose_mmoll_min, dto.glucose_mmoll_max],
    [Unit.MGDL]: [dto.glucose_mgdl_min, dto.glucose_mgdl_max],
  },
  [ReadingName.WAIST_MEASUREMENT]: {
    [Unit.CM]: [dto.waist_cm_min, dto.waist_cm_max],
    [Unit.INCH]: [dto.waist_inch_min, dto.waist_inch_max],
  },
  [ReadingName.WEIGHT_MEASUREMENT]: {
    [Unit.KG]: [dto.weight_kg_min, dto.weight_kg_max],
    [Unit.LBS]: [dto.weight_lbs_min, dto.weight_lbs_max],
  },
  [ReadingName.PROTEIN_INGESTION]: {
    [Unit.GRAMS]: [dto.protein_g_min, dto.protein_g_max],
    [Unit.KCAL]: [dto.protein_kcal_min, dto.protein_kcal_max],
  },
  [ReadingName.FAT_INGESTION]: {
    [Unit.GRAMS]: [dto.fat_g_min, dto.fat_g_max],
    [Unit.KCAL]: [dto.fat_kcal_min, dto.fat_kcal_max],
  },
  [ReadingName.CARBS_INGESTION]: {
    [Unit.GRAMS]: [dto.carbohydrates_g_min, dto.carbohydrates_g_max],
    [Unit.KCAL]: [dto.carbohydrates_kcal_min, dto.carbohydrates_kcal_max],
  },
  [ReadingName.CALORIES_INGESTION]: {
    [Unit.KCAL]: [dto.calories_min, dto.calories_max],
  },
  [ReadingName.VITAMIN_D_INGESTION]: {
    [Unit.IU]: [dto.vitamin_d_iu_min, dto.vitamin_d_iu_max],
    [Unit.MCG]: [dto.vitamin_d_mcg_min, dto.vitamin_d_mcg_max],
  },
  [ReadingName.MAGNESIUM_INGESTION]: {
    [Unit.MG]: [dto.magnesium_min, dto.magnesium_max],
  },
  [ReadingName.POTASSIUM_INGESTION]: {
    [Unit.MG]: [dto.potassium_min, dto.potassium_max],
  },
  [ReadingName.OMEGA_3_INGESTION]: {
    [Unit.MG]: [dto.omega_3_min, dto.omega_3_max],
  },
  [ReadingName.OMEGA_3_EPA_INGESTION]: {
    [Unit.MG]: [dto.omega_3_epa_min, dto.omega_3_epa_max],
  },
  [ReadingName.OMEGA_3_DHA_INGESTION]: {
    [Unit.MG]: [dto.omega_3_dha_min, dto.omega_3_dha_max],
  },
  [ReadingName.ZINC_INGESTION]: {
    [Unit.MG]: [dto.zinc_min, dto.zinc_max],
  },
  [ReadingName.VITAMIN_B12_INGESTION]: {
    [Unit.MCG]: [dto.vitamin_b12_min, dto.vitamin_b12_max],
  },
  [ReadingName.SELENIUM_INGESTION]: {
    [Unit.MCG]: [dto.selenium_min, dto.selenium_max],
  },
  [ReadingName.BMI_MEASUREMENT]: {
    [Unit.KG_M2]: [dto.bmi_min, dto.bmi_max],
  },
  [ReadingName.HEART_RATE_MEASUREMENT]: {
    [Unit.BPM]: [dto.hr_min, dto.hr_max],
  },
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: {
    [Unit.MS]: [dto.hrv_min, dto.hrv_max],
  },
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: {
    [Unit.MMHG]: [
      dto.blood_pressure_systolic_min,
      dto.blood_pressure_systolic_max,
    ],
  },
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: {
    [Unit.MMHG]: [
      dto.blood_pressure_diastolic_min,
      dto.blood_pressure_diastolic_max,
    ],
  },
  [ReadingName.SLEEP_SESSION_MEASUREMENT]: {
    [Unit.S]: [dto.sleep_session_min, dto.sleep_session_max],
  },
  [ReadingName.INSULIN_SERUM_BLOOD]: {
    [Unit.PMOLL]: [dto.insulin_serum_pmoll_min, dto.insulin_serum_pmoll_max],
    [Unit.MCUL]: [dto.insulin_serum_mcul_min, dto.insulin_serum_mcul_max],
  },
  [ReadingName.HDL_BLOOD]: {
    [Unit.MMOLL]: [dto.hdl_mmol_min, dto.hdl_mmol_max],
    [Unit.MGDL]: [dto.hdl_mgdl_min, dto.hdl_mgdl_max],
  },
  [ReadingName.LDL_BLOOD]: {
    [Unit.MMOLL]: [dto.ldl_mmoll_min, dto.ldl_mmoll_max],
    [Unit.MGDL]: [dto.ldl_mgdl_min, dto.ldl_mgdl_max],
  },
  [ReadingName.TRIGLYCERIDES_BLOOD]: {
    [Unit.MMOLL]: [dto.triglycerides_mmoll_min, dto.triglycerides_mmoll_max],
    [Unit.MGDL]: [dto.triglycerides_mgdl_min, dto.triglycerides_mgdl_max],
  },
  [ReadingName.HBA1C_BLOOD]: {
    [Unit.PERCENTAGE]: [dto.hba1c_percentage_min, dto.hba1c_percentage_max],
    [Unit.MMOL_MOL]: [dto.hba1c_mmol_mol_min, dto.hba1c_mmol_mol_max],
  },
  [ReadingName.APOB_BLOOD]: {
    [Unit.MGDL]: [dto.apob_mgdl_min, dto.apob_mgdl_max],
    [Unit.GL]: [dto.apob_gl_min, dto.apob_gl_max],
  },
  [ReadingName.APOAL_BLOOD]: {
    [Unit.MGDL]: [dto.apoal_mgdl_min, dto.apoal_mgdl_max],
    [Unit.GL]: [dto.apoal_gl_min, dto.apoal_gl_max],
  },
  [ReadingName.T3_BLOOD]: {
    [Unit.NMOLL]: [dto.t3_nmoll_min, dto.t3_nmoll_max],
    [Unit.NGDL]: [dto.t3_ngdl_min, dto.t3_ngdl_max],
  },
  [ReadingName.FREE_T3_BLOOD]: {
    [Unit.PMOLL]: [dto.free_t3_pmoll_min, dto.free_t3_pmoll_max],
    [Unit.PGML]: [dto.free_t3_pgml_min, dto.free_t3_pgml_max],
  },
  [ReadingName.T4_BLOOD]: {
    [Unit.NMOLL]: [dto.t4_nmoll_min, dto.t4_nmoll_max],
    [Unit.MCGDL]: [dto.t4_mcgdl_min, dto.t4_mcgdl_max],
  },
  [ReadingName.FREE_T4_BLOOD]: {
    [Unit.PMOLL]: [dto.free_t4_pmoll_min, dto.free_t4_pmoll_max],
    [Unit.NGDL]: [dto.free_t4_ngdl_min, dto.free_t4_ngdl_max],
  },
  [ReadingName.TSH_BLOOD]: {
    [Unit.MLUL]: [dto.tsh_mlul_min, dto.tsh_mlul_max],
    [Unit.MCUML]: [dto.tsh_mcuml_min, dto.tsh_mcuml_max],
  },
  [ReadingName.CARNITINE_BLOOD]: {
    [Unit.MCMOLL]: [dto.carnitine_mcmoll_min, dto.carnitine_mcmoll_max],
    [Unit.MGDL]: [dto.carnitine_mgdl_min, dto.carnitine_mgdl_max],
  },
  [ReadingName.VISCERAL_FAT_PERCENTAGE_MEASUREMENT]: {
    [Unit.PERCENTAGE]: [
      dto.visceral_fat_percentage_min,
      dto.visceral_fat_percentage_max,
    ],
  },
  [ReadingName.ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT]: {
    [Unit.PERCENTAGE]: [
      dto.absolute_fat_percentage_min,
      dto.absolute_fat_percentage_max,
    ],
  },
  [ReadingName.MUSCLE_MASS_PERCENTAGE_MEASUREMENT]: {
    [Unit.PERCENTAGE]: [
      dto.muscle_mass_percentage_min,
      dto.muscle_mass_percentage_max,
    ],
  },
  [ReadingName.HSCRP_BLOOD]: {
    [Unit.MGL]: [dto.hscrp_min, dto.hscrp_max],
  },
  [ReadingName.ALANINE_AMINOTRANSFERASE_BLOOD]: {
    [Unit.UL]: [
      dto.alanine_aminotransferase_min,
      dto.alanine_aminotransferase_max,
    ],
  },
  [ReadingName.ALBUMIN_BLOOD]: {
    [Unit.GDL]: [dto.albumin_min, dto.albumin_max],
  },
  [ReadingName.ALKALINE_PHOSPHATASE_BLOOD]: {
    [Unit.UL]: [dto.alkaline_phosphatase_min, dto.alkaline_phosphatase_max],
  },
  [ReadingName.ASPARTATE_AMINOTRANSFERASE_BLOOD]: {
    [Unit.UL]: [
      dto.aspartate_aminotransferase_min,
      dto.aspartate_aminotransferase_max,
    ],
  },
  [ReadingName.BICARBONATE_BLOOD]: {
    [Unit.MEQL]: [dto.bicarbonate_min, dto.bicarbonate_max],
  },
  [ReadingName.BILIRUBIN_BLOOD]: {
    [Unit.MGDL]: [dto.bilirubin_min, dto.bilirubin_max],
  },
  [ReadingName.BLOOD_UREA_NITROGEN_BLOOD]: {
    [Unit.MGDL]: [dto.blood_urea_nitrogen_min, dto.blood_urea_nitrogen_max],
  },
  [ReadingName.CALCIUM_BLOOD]: {
    [Unit.MGDL]: [dto.calcium_min, dto.calcium_max],
  },
  [ReadingName.CHLORIDE_BLOOD]: {
    [Unit.MGDL]: [dto.chloride_min, dto.chloride_max],
  },
  [ReadingName.CREATININE_BLOOD]: {
    [Unit.MGDL]: [dto.creatinine_min, dto.creatinine_max],
  },
  [ReadingName.POTASSIUM_BLOOD]: {
    [Unit.MEQL]: [dto.potassium_blood_min, dto.potassium_blood_max],
  },
  [ReadingName.SODIUM_BLOOD]: {
    [Unit.MEQL]: [dto.sodium_min, dto.sodium_max],
  },
  [ReadingName.TOTAL_PROTEIN_BLOOD]: {
    [Unit.GDL]: [dto.total_protein_min, dto.total_protein_max],
  },
  [ReadingName.COPPER_BLOOD]: {
    [Unit.MCGDL]: [dto.copper_min, dto.copper_max],
  },
  [ReadingName.TNFA_BLOOD]: {
    [Unit.PGML]: [dto.tnfa_min, dto.tnfa_max],
  },
  [ReadingName.IL6_BLOOD]: {
    [Unit.PGML]: [dto.il6_min, dto.il6_max],
  },
  [ReadingName.BONE_MASS_MEASUREMENT]: {
    [Unit.KG]: [dto.bone_mass_kg_min, dto.bone_mass_kg_max],
    [Unit.LBS]: [dto.bone_mass_lbs_min, dto.bone_mass_lbs_max],
  },
  [ReadingName.FAT_FREE_MASS_MEASUREMENT]: {
    [Unit.KG]: [dto.fat_free_mass_kg_min, dto.fat_free_mass_kg_max],
    [Unit.LBS]: [dto.fat_free_mass_lbs_min, dto.fat_free_mass_lbs_max],
  },
  [ReadingName.HEIGHT_MEASUREMENT]: {
    [Unit.CM]: [dto.height_cm_min, dto.height_cm_max],
    [Unit.INCH]: [dto.height_inch_min, dto.height_inch_max],
  },
  [ReadingName.VISCERAL_FAT_MEASUREMENT]: {
    [Unit.KG]: [dto.visceral_fat_kg_min, dto.visceral_fat_kg_max],
    [Unit.LBS]: [dto.visceral_fat_lbs_min, dto.visceral_fat_lbs_max],
  },
  [ReadingName.ABSOLUTE_FAT_MEASUREMENT]: {
    [Unit.KG]: [dto.absolute_fat_kg_min, dto.absolute_fat_kg_max],
    [Unit.LBS]: [dto.absolute_fat_lbs_min, dto.absolute_fat_lbs_max],
  },
  [ReadingName.MUSCLE_MASS_MEASUREMENT]: {
    [Unit.KG]: [dto.muscle_mass_kg_min, dto.muscle_mass_kg_max],
    [Unit.LBS]: [dto.muscle_mass_lbs_min, dto.muscle_mass_lbs_max],
  },
  [ReadingName.ACTIVITY_LEVEL_MEASUREMENT]: {
    [Unit.NO_UNIT]: [dto.activity_level_min, dto.activity_level_max],
  },
  [ReadingName.INSULIN_BASAL_MDI]: {
    [Unit.IU]: [dto.insulin_basal_min, dto.insulin_basal_max],
  },
  [ReadingName.INSULIN_BOLUS_MDI]: {
    [Unit.IU]: [dto.insulin_bolus_min, dto.insulin_bolus_max],
  },
});
