import { useMemo } from 'react';
import clsx from 'clsx';

type ProgressBarProps = {
  currentStep?: number;
  maxSteps?: number;
};

export const ProgressBar = ({
  currentStep = 1,
  maxSteps = 10,
}: ProgressBarProps) => {
  const width = useMemo(
    () => Math.floor(((currentStep + 1) * 100) / maxSteps),
    [maxSteps, currentStep]
  );

  return (
    <div className="h-1 w-full bg-gkiGrey-300">
      <div
        className={clsx('h-1 bg-ketoneBlue-700')}
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
};
