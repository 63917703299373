import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  usePageVisitAnalytics,
  usePersistRecentPractice,
  useScrollRestorer,
} from 'shared/common/hooks';
import { routes } from 'shared/common/routes';
import { LogrocketContextProvider } from 'shared/common/services/logrocket';
import {
  AuthorizedSupportAccessStateContainer,
  ChatContainer,
  DarkModeContainer,
  ModalContainer,
  SkeletonThemeProvider,
} from 'shared/common/store';

import { MacrosAppContainer } from 'features/macros-app/store/macros-app.store';

import { StatefulInterceptors } from './interceptors';

const Main = React.lazy(() => import('pages/main/main.page'));
const Login = React.lazy(() => import('pages/login/login.page'));
const OauthLanding = React.lazy(
  () => import('pages/oauth-landing/oauth-landing.page')
);
const AuthorizedSupportAccessLanding = React.lazy(
  () =>
    import(
      'pages/authorized-support-access-landing/authorized-support-access-landing.page'
    )
);
const SessionEndPage = React.lazy(
  () => import('pages/session-end/session-end.page')
);

const App: React.FC = () => (
  <AuthorizedSupportAccessStateContainer>
    <LogrocketContextProvider>
      <ChatContainer>
        <ModalContainer>
          <DarkModeContainer>
            <MacrosAppContainer>
              <SkeletonThemeProvider>
                <Router>
                  <RouterDependantHooksInitializer />
                  <StatefulInterceptors />
                  <Switch>
                    <Route path={routes.login} component={Login} />
                    <Route
                      path={routes.oauthLanding}
                      component={OauthLanding}
                    />
                    <Route
                      path={routes.authorizedSupportAccessLanding}
                      component={AuthorizedSupportAccessLanding}
                    />
                    <Route path={routes.logout} component={SessionEndPage} />
                    <Route
                      path={routes.sessionExpired}
                      render={() => <SessionEndPage isExpiredSession />}
                    />
                    <Route path={routes.root} component={Main} />
                  </Switch>
                </Router>
              </SkeletonThemeProvider>
            </MacrosAppContainer>
          </DarkModeContainer>
        </ModalContainer>
      </ChatContainer>
    </LogrocketContextProvider>
  </AuthorizedSupportAccessStateContainer>
);

const RouterDependantHooksInitializer = () => {
  useScrollRestorer();
  usePersistRecentPractice();
  usePageVisitAnalytics();

  return null;
};

export default App;
