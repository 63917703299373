import i18n from 'i18next';

export const steps = [
  'gender',
  'age',
  'height',
  'weight',
  'activityLevel',
  'bodyFatLevel',
  'primaryHealthGoal',
  'recommendedDailyCarbsIntakeChoice',
  'dailyCarbIntake',
  'recommendedDailyProteinIntakeChoice',
  'recommendedDailyProteinIntake',
  'recommendedDailyFatIntake',
  'summary',
] as const;

export const activityLevels = {
  sedentary: {
    value: 1,
    label: i18n.t('Sedentary'),
  },
  lightly_active: {
    value: 2,
    label: i18n.t('Lightly active'),
  },
  moderately_active: {
    value: 3,
    label: i18n.t('Moderately Active'),
  },
  very_active: {
    value: 4,
    label: i18n.t('Very Active'),
  },
  athlete_bodybuilder: {
    value: 5,
    label: i18n.t('Athletes / Bodybuilders'),
  },
};

export const calculatorConsts = {
  convertInchesToCm: 2.54,
  convertFeetToCm: 30.48,
  convertFeetToInches: 12,
  convertKgToLbs: 2.2,
  heightBmr: 6.25,
  weightBmr: 10,
  ageBmr: 5,
  bmrForMale: 5,
  bmrForFemale: 161,
  bfiMale: 16.2,
  bfiFemale: 5.4,
  muscleGainGPerKg: 1.4,
  settings: {
    // carbs
    carbs: 20,
    carbs_suggested: 20,
    carbs_min: 0,
    carbs_max: 50,
    // protein
    protein_min: 20,
    // fat
    fat_min: -30,
    fat_max: 30,
    fat_suggested: -20,
    fat_min_g: 75,
    fat_max_g: 75,
    fat_chosen_g: 75,
    fat_maintenance: 0,
    // activity level
    activityLevels: {
      [activityLevels.sedentary.value]: 1.1,
      [activityLevels.lightly_active.value]: 1.232,
      [activityLevels.moderately_active.value]: 1.386,
      [activityLevels.very_active.value]: 1.617,
      [activityLevels.athlete_bodybuilder.value]: 1.9,
    },
  },
};

export const modes = ['settings', 'calculator'] as const;

export const primaryHealthGoals = {
  body_fat_reduction: 'Body Fat Reduction',
  weight_maintenance: 'Weight Maintenance',
  diabetes_type_ii_reduction: 'Diabetes Type II Reduction',
  diabetes_type_i_support: 'Diabetes Type I Support',
} as const;

export const genders = {
  M: 'M',
  F: 'F',
} as const;

export const bodyFatLevels = {
  lean: {
    label: 'lean',
    valueMan: '6-13',
    valueWoman: '14-20',
  },
  athletic: {
    label: 'athletic',
    valueMan: '14-17',
    valueWoman: '21-24',
  },
  average: {
    label: 'average',
    valueMan: '18-24',
    valueWoman: '25-31',
  },
  overweight: {
    label: 'overweight',
    valueMan: '>25',
    valueWoman: '>32',
  },
};
