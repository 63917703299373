import { intervalToDuration } from 'date-fns';
import i18n from 'i18next';

import { colors } from 'consts';

export const sleepPhasesColorMap = (y: number) => {
  const statusMap: { [key in number]: string } = {
    1: colors.emeraldSleep,
    2: colors.blueSleep,
    3: colors.indigoSleep,
    4: colors.violetSleep,
  };

  return statusMap[y];
};

export enum SleepPhases {
  'Awake' = 1,
  'Light' = 2,
  'Deep' = 3,
  'REM' = 4,
}

export const formatSleepDuration = (value: number, compact?: boolean) => {
  const duration = intervalToDuration({ start: 0, end: value * 1000 });
  const days = duration.days || 0;
  const minutes = duration.minutes || 0;
  const hours = duration.hours || 0;

  // edge case if sleep session would be 24h or longer for some reason 🤷‍♂️
  const getHours = days ? days * 24 + hours : hours;

  const renderDuration = () => {
    if (!minutes) return `${getHours}${i18n.t('h')}`;
    else if (!getHours) return `${minutes}min`;

    return compact
      ? `${getHours}${i18n.t('h')}${minutes}m`
      : `${getHours}${i18n.t('h')} ${minutes}min`;
  };

  return renderDuration();
};
