import { FC } from 'react';

import { Select } from 'components/forms-v2/inputs/select/Select';
import { ReadingName } from 'domain/domain.models';
import { getExcludedGkrReadingName } from 'domain/domain.utils';
import { GkrType } from 'models/gkr-type';

import { getReadingNamesSelectorOptions } from './utils';

interface ReadingNamesSelectorProps {
  gkrType: GkrType;
  readingNames: [ReadingName, ReadingName, ReadingName];
  setReadingNames: (name: ReadingName, idx: number) => void;
}

export const ReadingNamesSelector: FC<ReadingNamesSelectorProps> = ({
  gkrType,
  readingNames,
  setReadingNames,
}) => (
  <div className="flex w-full flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0">
    <Select
      options={getReadingNamesSelectorOptions({
        excludedReadingNames: [
          ...readingNames.filter((_, i) => i !== 0),
          getExcludedGkrReadingName(gkrType),
          ReadingName.ACTIVITY_LEVEL_MEASUREMENT,
        ],
      })}
      className="flex-1"
      value={readingNames[0]}
      onChange={(readingName) => setReadingNames(readingName, 0)}
      withCategories
    />
    <Select
      options={getReadingNamesSelectorOptions({
        excludedReadingNames: [
          ...readingNames.filter((_, i) => i !== 1),
          getExcludedGkrReadingName(gkrType),
          ReadingName.ACTIVITY_LEVEL_MEASUREMENT,
        ],
      })}
      className="flex-1"
      value={readingNames[1]}
      onChange={(readingName) => setReadingNames(readingName, 1)}
      withCategories
    />
    <Select
      options={getReadingNamesSelectorOptions({
        excludedReadingNames: [
          ...readingNames.filter((_, i) => i !== 2),
          getExcludedGkrReadingName(gkrType),
          ReadingName.ACTIVITY_LEVEL_MEASUREMENT,
        ],
      })}
      className="flex-1"
      value={readingNames[2]}
      onChange={(readingName) => setReadingNames(readingName, 2)}
      withCategories
    />
  </div>
);
