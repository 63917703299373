import { apiHttpClient, oauthHttpClient } from 'shared/common/api';
import { AuthGrantTypes, HttpHeaders } from 'shared/common/consts';

import { userApiRoutes } from '../consts/user-api-routes';
import {
  NewAuthTokenResponse,
  RefreshUserSessionDto,
  VerifyTokenDto,
} from '../types/auth';
import { DexcomConnectionStatusDto } from '../types/dexcom-connection-status-dto';
import { PartnerConnectionStatusDto } from '../types/partner-connection-status-dto';
import { UserDto } from '../types/user';

export const userApiFactory = (httpClient: typeof apiHttpClient) => ({
  getPartnerConnectionStatus: async (partner: string) => {
    const res = await httpClient<PartnerConnectionStatusDto>(
      userApiRoutes.partnerCredentials(partner),
      {
        method: 'GET',
      }
    );

    return res.data;
  },

  disconnectPartner: async (partnerName: string) => {
    const res = await httpClient(
      userApiRoutes.partnerCredentials(partnerName),
      {
        method: 'DELETE',
      }
    );

    return res.data;
  },

  revokeAccessPartner: async (partnerId: string) => {
    const res = await httpClient(userApiRoutes.partnerRevokeAccess(partnerId), {
      method: 'DELETE',
    });

    return res.data;
  },

  syncPartner: async (partner: string) => {
    // triggers delta_sync from last_sync_timestamp
    const res = await httpClient(userApiRoutes.partnerSync(partner), {
      method: 'POST',
      data: {
        sync_type: 'delta_sync',
      },
    });

    return res.data;
  },

  getDexcomConnectionStatus: async () => {
    const res = await httpClient<DexcomConnectionStatusDto>(
      userApiRoutes.dexcomCredentials,
      {
        method: 'GET',
      }
    );

    return res.data;
  },

  disconnectDexcom: async () => {
    const res = await httpClient(userApiRoutes.dexcomCredentials, {
      method: 'DELETE',
    });

    return res.data;
  },

  syncDexcom: async () => {
    // triggers delta_sync from last_sync_timestamp
    const res = await httpClient(userApiRoutes.dexcomSync, {
      method: 'POST',
      data: {
        sync_type: 'delta_sync',
      },
    });

    return res.data;
  },

  getOAuthScopes: async (clientId: string) => {
    const res = await httpClient<{ scope: string[] }>(
      userApiRoutes.oAuthScopes,
      {
        method: 'GET',
        pathParams: {
          clientId,
        },
      }
    );

    return res.data;
  },
});

export const userOauthApiFactory = (httpClient: typeof oauthHttpClient) => ({
  getLoggedInUser: async () => {
    const res = await httpClient<UserDto>(userApiRoutes.users, {
      method: 'GET',
    });

    return res.data;
  },

  logoutLoggedInUser: async ({
    token,
    oauthClientId,
  }: {
    token: string;
    oauthClientId: string;
  }) => {
    const data = {
      token,
      token_type_hint: 'access_token',
      client_id: oauthClientId,
    };

    const formData = mapToFormData(data);

    return httpClient<UserDto>(userApiRoutes.oAuthRevoke, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  checkAuthorizedAccessUserIdValidity: async (supportedUserId: string) => {
    const res = await httpClient(userApiRoutes.users, {
      method: 'GET',
      headers: { [HttpHeaders.ASSUME_USER_ID]: supportedUserId },
    });

    return res.data;
  },

  updateCurrentUser: async (data: Partial<UserDto>) => {
    const res = await httpClient(userApiRoutes.users, {
      method: 'PATCH',
      data,
    });

    return res.data;
  },

  /*
    Used to update patient user data by hcp
  */
  updateUser: async ({
    userId,
    data,
  }: {
    userId: string;
    data: Partial<UserDto>;
  }) => {
    const res = await httpClient(userApiRoutes.user, {
      method: 'PATCH',
      pathParams: { id: userId },
      data,
    });

    return res.data;
  },

  downloadPrivacyReport: async ({
    data,
  }: {
    data: {
      action: 'download_data';
      download_data_scope: {
        personal_information: boolean;
        readings: boolean;
        activity_logs: boolean;
      };
    };
  }) => {
    const res = await httpClient(userApiRoutes.users, {
      method: 'PUT',
      data,
    });

    return res.data;
  },

  changePassword: async ({
    data,
  }: {
    data: {
      action: 'change_password';
      current_password: string;
      new_password: string;
      confirm_password: string;
    };
  }) => {
    const res = await httpClient(userApiRoutes.users, {
      method: 'PUT',
      data,
    });

    return res.data;
  },

  verifyToken: async (data: VerifyTokenDto) => {
    const formData = mapToFormData(data);

    const res = await httpClient<NewAuthTokenResponse>(
      userApiRoutes.oAuthToken,
      {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return res.data;
  },

  refreshUserSession: async ({
    refreshToken,
    scopes,
    oAuthClientId,
  }: {
    refreshToken: string;
    scopes: string;
    oAuthClientId: string;
  }) => {
    const data: RefreshUserSessionDto = {
      refresh_token: refreshToken,
      grant_type: AuthGrantTypes.REFRESH_TOKEN,
      scope: scopes,
      client_id: oAuthClientId,
    };

    const formData = mapToFormData(data);

    const res = await httpClient<NewAuthTokenResponse>(
      userApiRoutes.oAuthToken,
      {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return res.data;
  },

  deleteUser: async () => {
    const res = await httpClient(userApiRoutes.users, {
      method: 'DELETE',
    });

    return res.data;
  },
});

const mapToFormData = (object: object): FormData => {
  const form = new FormData();
  Object.entries(object).forEach(([key, value]) => form.append(key, value));
  return form;
};
