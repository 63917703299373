import i18n from 'i18next';
import * as convert from 'shared/common/utils/unit-converters/unit-converters';

import { colors } from 'consts';
import { ReadingTypeColor } from 'consts/colors/colors';
import { DomainConfigs, ReadingName } from 'domain/domain.models';
import { ReadingType, Unit } from 'models';
import { ReadingCategory } from 'models/reading-category';
import { ReadingEntryType } from 'models/reading-entry-type';
import { ReadingSampleType } from 'models/reading-sample-type';

export const domainConfig: DomainConfigs = {
  [ReadingName.GLUCOSE_BLOOD]: {
    readingName: ReadingName.GLUCOSE_BLOOD,
    readingType: ReadingType.GLUCOSE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.MANUAL, ReadingEntryType.METER],
    units: [Unit.MMOLL, Unit.MGDL],
    color: ReadingTypeColor.glucoseBrown,
    colorPalette: colors.glucoseBrown,
    colorPaletteDark: colors.glucoseBrownDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.red[900],
      inRange: colors.glucoseBrown[600],
      withinUpperTresholdRange: colors.ketoneBreath[700],
      aboveUpperTresholdRange: colors.purple[800],
    },
    defaultRanges: {
      [Unit.MMOLL]: convert.mgdlToMmoll([70, 110]),
      [Unit.MGDL]: [70, 110],
    },
    boundaryRanges: {
      [Unit.MMOLL]: convert.mgdlToMmoll([0, 1100]),
      [Unit.MGDL]: [0, 1100],
    },
    label: () => i18n.t('Glucose Blood'),
    slug: 'glucose',
    canBeAddedManually: true,
  },

  [ReadingName.GLUCOSE_INTERSTITIAL]: {
    readingName: ReadingName.GLUCOSE_INTERSTITIAL,
    readingType: ReadingType.GLUCOSE,
    readingSampleType: ReadingSampleType.INTERSTITIAL,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.CONTINUOUS],
    units: [Unit.MMOLL, Unit.MGDL],
    color: ReadingTypeColor.glucoseBrown,
    colorPalette: colors.glucoseBrown,
    colorPaletteDark: colors.glucoseBrownDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.red[900],
      inRange: colors.glucoseBrown[600],
      withinUpperTresholdRange: colors.ketoneBreath[700],
      aboveUpperTresholdRange: colors.purple[800],
    },
    defaultRanges: {
      [Unit.MMOLL]: convert.mgdlToMmoll([70, 110]),
      [Unit.MGDL]: [70, 110],
    },
    boundaryRanges: {
      [Unit.MMOLL]: convert.mgdlToMmoll([0, 1100]),
      [Unit.MGDL]: [0, 1100],
    },
    label: () => i18n.t('Glucose CGM'),
    slug: 'glucose-cgm',
    canBeAddedManually: false,
  },

  [ReadingName.KETONE_BLOOD]: {
    readingName: ReadingName.KETONE_BLOOD,
    readingType: ReadingType.KETONE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.MANUAL, ReadingEntryType.METER],
    units: [Unit.MMOLL],
    color: ReadingTypeColor.ketoneBlue,
    colorPalette: colors.ketoneBlue,
    colorPaletteDark: colors.ketoneBlueDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneBlue[300],
      withinLowerTresholdRange: colors.ketoneBlue[400],
      inRange: colors.ketoneBlue[600],
      withinUpperTresholdRange: colors.coolGray[600],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMOLL]: [0.5, 8.0],
    },
    boundaryRanges: {
      [Unit.MMOLL]: [0, 80],
    },
    label: () => i18n.t('Ketones Blood'),
    slug: 'ketones',
    canBeAddedManually: true,
  },

  [ReadingName.KETONE_BREATH_ACE]: {
    readingName: ReadingName.KETONE_BREATH_ACE,
    readingType: ReadingType.KETONE,
    readingSampleType: ReadingSampleType.BREATH_ACE,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.ACE],
    color: ReadingTypeColor.ketoneBreath,
    colorPalette: colors.ketoneBreath,
    colorPaletteDark: colors.ketoneBreathDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneBreath[200],
      withinLowerTresholdRange: colors.ketoneBreath[400],
      inRange: colors.ketoneBreath[600],
      withinUpperTresholdRange: colors.pink[900],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.ACE]: [0, 250],
    },
    boundaryRanges: {
      [Unit.ACE]: [0, 999],
    },
    label: () => i18n.t('Ketones Breath (ACE)'),
    labelIncludesUnit: true,
    slug: 'ketones-breath-ace',
    canBeAddedManually: true,
  },

  [ReadingName.KETONE_BREATH_PPM]: {
    readingName: ReadingName.KETONE_BREATH_PPM,
    readingType: ReadingType.KETONE,
    readingSampleType: ReadingSampleType.BREATH_PPM,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PPM],
    color: ReadingTypeColor.ketoneBreath,
    colorPalette: colors.ketoneBreath,
    colorPaletteDark: colors.ketoneBreathDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneBreath[200],
      withinLowerTresholdRange: colors.ketoneBreath[400],
      inRange: colors.ketoneBreath[600],
      withinUpperTresholdRange: colors.pink[900],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PPM]: [0, 100],
    },
    boundaryRanges: {
      [Unit.PPM]: [0, 999],
    },
    label: () => i18n.t('Ketones Breath (PPM)'),
    labelIncludesUnit: true,
    slug: 'ketones-breath-ppm',
    canBeAddedManually: true,
  },

  [ReadingName.KETONE_URINE]: {
    readingName: ReadingName.KETONE_URINE,
    readingType: ReadingType.KETONE,
    readingSampleType: ReadingSampleType.URINE,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMOLL],
    color: ReadingTypeColor.ketoneUrine,
    colorPalette: colors.ketoneUrine,
    colorPaletteDark: colors.ketoneUrineDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneUrine[200],
      withinLowerTresholdRange: colors.ketoneUrine[400],
      inRange: colors.ketoneUrine[600],
      withinUpperTresholdRange: colors.pink[700],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMOLL]: [0.5, 8.0],
    },
    boundaryRanges: {
      [Unit.MMOLL]: [0, 80],
    },
    label: () => i18n.t('Ketones Urine'),
    slug: 'ketones-urine',
    canBeAddedManually: true,
  },

  [ReadingName.GKI_BLOOD]: {
    readingName: ReadingName.GKI_BLOOD,
    readingType: ReadingType.GLUCOSE_KETONE_INDEX,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.CALCULATED],
    units: [Unit.NO_UNIT],
    color: ReadingTypeColor.gkiGrey,
    colorPalette: colors.gkiGrey,
    colorPaletteDark: colors.gkiGreyDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneBlue[600],
      withinLowerTresholdRange: colors.ketoneBlue[700],
      inRange: colors.gkiGrey[600],
      withinUpperTresholdRange: colors.gray[600],
      aboveUpperTresholdRange: colors.glucoseBrown[600],
    },
    defaultRanges: {
      [Unit.NO_UNIT]: [0, 10],
    },
    boundaryRanges: {
      [Unit.NO_UNIT]: [0, 100],
    },
    label: () => i18n.t('GKI'),
    slug: 'gki',
    canBeAddedManually: true,
  },

  [ReadingName.DBR_BLOOD]: {
    readingName: ReadingName.DBR_BLOOD,
    readingType: ReadingType.DBR,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.METABOLISM,
    entryTypes: [ReadingEntryType.CALCULATED],
    units: [Unit.NO_UNIT],
    color: ReadingTypeColor.gkiGrey,
    colorPalette: colors.gkiGrey,
    colorPaletteDark: colors.gkiGreyDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.ketoneBlue[600],
      withinLowerTresholdRange: colors.ketoneBlue[700],
      inRange: colors.gkiGrey[600],
      withinUpperTresholdRange: colors.gray[600],
      aboveUpperTresholdRange: colors.glucoseBrown[600],
    },
    defaultRanges: {
      [Unit.NO_UNIT]: [0, 180],
    },
    boundaryRanges: {
      [Unit.NO_UNIT]: [0, 1800],
    },
    label: () => i18n.t('Dr. Boz Ratio'),
    slug: 'dbr',
    canBeAddedManually: true,
  },

  [ReadingName.WAIST_MEASUREMENT]: {
    readingName: ReadingName.WAIST_MEASUREMENT,
    readingType: ReadingType.WAIST,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.CM, Unit.INCH],
    color: ReadingTypeColor.waist,
    colorPalette: colors.waist,
    colorPaletteDark: colors.waistDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.CM]: [40, 100],
      [Unit.INCH]: convert.cmToInch([40, 100]),
    },
    boundaryRanges: {
      [Unit.CM]: [0, 1000],
      [Unit.INCH]: convert.cmToInch([0, 1000]),
    },
    label: () => i18n.t('Waist'),
    slug: 'waist',
    canBeAddedManually: true,
  },

  [ReadingName.WEIGHT_MEASUREMENT]: {
    readingName: ReadingName.WEIGHT_MEASUREMENT,
    readingType: ReadingType.WEIGHT,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [20, 120],
      [Unit.LBS]: convert.kgToLbs([20, 120]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 1200],
      [Unit.LBS]: convert.kgToLbs([0, 1200]),
    },
    label: () => i18n.t('Weight'),
    slug: 'weight',
    canBeAddedManually: true,
  },
  [ReadingName.HEIGHT_MEASUREMENT]: {
    readingName: ReadingName.HEIGHT_MEASUREMENT,
    readingType: ReadingType.HEIGHT,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.CM, Unit.INCH],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.CM]: [0, 9999],
      [Unit.INCH]: convert.cmToInch([0, 9999]),
    },
    boundaryRanges: {
      [Unit.CM]: [0, 9999],
      [Unit.INCH]: convert.cmToInch([0, 9999]),
    },
    label: () => i18n.t('Height'),
    slug: 'height',
    canBeAddedManually: true,
  },

  [ReadingName.CARBS_INGESTION]: {
    readingName: ReadingName.CARBS_INGESTION,
    readingType: ReadingType.CARBS,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MACROS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.GRAMS, Unit.KCAL],
    color: ReadingTypeColor.carbsBlue,
    colorPalette: colors.carbsBlue,
    colorPaletteDark: colors.carbsBlueDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.ketoneBreath[600],
      inRange: colors.carbsBlue[700],
      withinUpperTresholdRange: colors.ketoneBreath[600],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.GRAMS]: [0, 100],
      [Unit.KCAL]: convert.gramsToKcal([0, 100]),
    },
    boundaryRanges: {
      [Unit.GRAMS]: [0, 1000],
      [Unit.KCAL]: convert.gramsToKcal([0, 1000]),
    },
    label: () => i18n.t('Carbohydrates'),
    slug: 'carbohydrates',
    canBeAddedManually: true,
  },

  [ReadingName.PROTEIN_INGESTION]: {
    readingName: ReadingName.PROTEIN_INGESTION,
    readingType: ReadingType.PROTEIN,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MACROS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.GRAMS, Unit.KCAL],
    color: ReadingTypeColor.proteinGreen,
    colorPalette: colors.proteinGreen,
    colorPaletteDark: colors.proteinGreenDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.yellow[700],
      inRange: colors.proteinGreen[700],
      withinUpperTresholdRange: colors.yellow[700],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.GRAMS]: [50, 100],
      [Unit.KCAL]: convert.gramsToKcal([50, 100]),
    },
    boundaryRanges: {
      [Unit.GRAMS]: [0, 1000],
      [Unit.KCAL]: convert.gramsToKcal([0, 1000]),
    },
    label: () => i18n.t('Protein'),
    slug: 'protein',
    canBeAddedManually: true,
  },

  [ReadingName.FAT_INGESTION]: {
    readingName: ReadingName.FAT_INGESTION,
    readingType: ReadingType.FAT,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MACROS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.GRAMS, Unit.KCAL],
    color: ReadingTypeColor.fatPink,
    colorPalette: colors.fatPink,
    colorPaletteDark: colors.fatPinkDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.fatPink[900],
      inRange: colors.fatPink[700],
      withinUpperTresholdRange: colors.fatPink[900],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.GRAMS]: [50, 100],
      [Unit.KCAL]: convert.fatGramsToKcal([50, 100]),
    },
    boundaryRanges: {
      [Unit.GRAMS]: [0, 1000],
      [Unit.KCAL]: convert.fatGramsToKcal([0, 1000]),
    },
    label: () => i18n.t('Fats'),
    slug: 'fats',
    canBeAddedManually: true,
  },

  [ReadingName.CALORIES_INGESTION]: {
    readingName: ReadingName.CALORIES_INGESTION,
    readingType: ReadingType.CALORIES,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MACROS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KCAL],
    color: ReadingTypeColor.calories,
    colorPalette: colors.calories,
    colorPaletteDark: colors.caloriesDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KCAL]: [1200, 2200],
    },
    boundaryRanges: {
      [Unit.KCAL]: [0, 22000],
    },
    label: () => i18n.t('Calories'),
    slug: 'calories',
    canBeAddedManually: true,
  },

  [ReadingName.VITAMIN_D_INGESTION]: {
    readingName: ReadingName.VITAMIN_D_INGESTION,
    readingType: ReadingType.VITAMIN_D,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.IU, Unit.MCG],
    color: ReadingTypeColor.vitaminD,
    colorPalette: colors.vitaminD,
    colorPaletteDark: colors.vitaminDDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.IU]: [600, 8000],
      [Unit.MCG]: convert.iuToMcg([600, 8000]),
    },
    boundaryRanges: {
      [Unit.IU]: [0, 80000],
      [Unit.MCG]: convert.iuToMcg([0, 80000]),
    },
    label: () => i18n.t('Vitamin D'),
    slug: 'vitamin-d',
    canBeAddedManually: true,
  },

  [ReadingName.MAGNESIUM_INGESTION]: {
    readingName: ReadingName.MAGNESIUM_INGESTION,
    readingType: ReadingType.MAGNESIUM,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.magnesium,
    colorPalette: colors.magnesium,
    colorPaletteDark: colors.magnesiumDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [0, 1000],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 4200],
    },
    label: () => i18n.t('Magnesium'),
    slug: 'magnesium',
    canBeAddedManually: true,
  },

  [ReadingName.POTASSIUM_INGESTION]: {
    readingName: ReadingName.POTASSIUM_INGESTION,
    readingType: ReadingType.POTASSIUM,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.potassium,
    colorPalette: colors.potassium,
    colorPaletteDark: colors.potassiumDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [2000, 3400],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 34000],
    },
    label: () => i18n.t('Potassium'),
    slug: 'potassium',
    canBeAddedManually: true,
  },

  [ReadingName.OMEGA_3_INGESTION]: {
    readingName: ReadingName.OMEGA_3_INGESTION,
    readingType: ReadingType.OMEGA_3,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.omega3,
    colorPalette: colors.omega3,
    colorPaletteDark: colors.omega3Dark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [1100, 2000],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 20000],
    },
    label: () => i18n.t('Omega 3'),
    slug: 'omega-3',
    canBeAddedManually: true,
  },

  [ReadingName.OMEGA_3_EPA_INGESTION]: {
    readingName: ReadingName.OMEGA_3_EPA_INGESTION,
    readingType: ReadingType.OMEGA_3_EPA,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.omega3epa,
    colorPalette: colors.omega3epa,
    colorPaletteDark: colors.omega3epaDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [500, 1800],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 18000],
    },
    label: () => i18n.t('Omega 3 EPA'),
    slug: 'omega-3-epa',
    canBeAddedManually: true,
  },

  [ReadingName.OMEGA_3_DHA_INGESTION]: {
    readingName: ReadingName.OMEGA_3_DHA_INGESTION,
    readingType: ReadingType.OMEGA_3_DHA,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.omega3dha,
    colorPalette: colors.omega3dha,
    colorPaletteDark: colors.omega3dhaDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [500, 1800],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 18000],
    },
    label: () => i18n.t('Omega 3 DHA'),
    slug: 'omega-3-dha',
    canBeAddedManually: true,
  },

  [ReadingName.ZINC_INGESTION]: {
    readingName: ReadingName.ZINC_INGESTION,
    readingType: ReadingType.ZINC,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MG],
    color: ReadingTypeColor.zinc,
    colorPalette: colors.zinc,
    colorPaletteDark: colors.zincDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MG]: [8, 13],
    },
    boundaryRanges: {
      [Unit.MG]: [0, 130],
    },
    label: () => i18n.t('Zinc'),
    slug: 'zinc',
    canBeAddedManually: true,
  },

  [ReadingName.VITAMIN_B12_INGESTION]: {
    readingName: ReadingName.VITAMIN_B12_INGESTION,
    readingType: ReadingType.VITAMIN_B12,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MCG],
    color: ReadingTypeColor.vitaminB12,
    colorPalette: colors.vitaminB12,
    colorPaletteDark: colors.vitaminB12Dark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MCG]: [2.4, 2.8],
    },
    boundaryRanges: {
      [Unit.MCG]: [0, 28],
    },
    label: () => i18n.t('Vitamin B12'),
    slug: 'vitamin-b-12',
    canBeAddedManually: true,
  },

  [ReadingName.SELENIUM_INGESTION]: {
    readingName: ReadingName.SELENIUM_INGESTION,
    readingType: ReadingType.SELENIUM,
    readingSampleType: ReadingSampleType.INGESTION,
    category: ReadingCategory.MICRONUTRIENTS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MCG],
    color: ReadingTypeColor.selenium,
    colorPalette: colors.selenium,
    colorPaletteDark: colors.seleniumDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MCG]: [55, 70],
    },
    boundaryRanges: {
      [Unit.MCG]: [0, 700],
    },
    label: () => i18n.t('Selenium'),
    slug: 'selenium',
    canBeAddedManually: true,
  },

  [ReadingName.BMI_MEASUREMENT]: {
    readingName: ReadingName.BMI_MEASUREMENT,
    readingType: ReadingType.BMI,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG_M2],
    color: ReadingTypeColor.bmi,
    colorPalette: colors.bmi,
    colorPaletteDark: colors.bmiDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG_M2]: [18.5, 24.9],
    },
    boundaryRanges: {
      [Unit.KG_M2]: [0, 249],
    },
    label: () => i18n.t('BMI'),
    slug: 'bmi',
    canBeAddedManually: true,
  },

  [ReadingName.HEART_RATE_MEASUREMENT]: {
    readingName: ReadingName.HEART_RATE_MEASUREMENT,
    readingType: ReadingType.HEART_RATE,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.CONTINUOUS],
    units: [Unit.BPM],
    color: ReadingTypeColor.heartRate,
    colorPalette: colors.heartRate,
    colorPaletteDark: colors.heartRateDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.BPM]: [40, 180],
    },
    boundaryRanges: {
      [Unit.BPM]: [0, 1800],
    },
    label: () => i18n.t('Heart Rate'),
    slug: 'heart-rate',
    canBeAddedManually: false,
  },

  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: {
    readingName: ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT,
    readingType: ReadingType.HEART_RATE_VARIABILITY,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.CONTINUOUS],
    units: [Unit.MS],
    color: ReadingTypeColor.heartRate,
    colorPalette: colors.heartRate,
    colorPaletteDark: colors.heartRateDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MS]: [20, 100],
    },
    boundaryRanges: {
      [Unit.MS]: [0, 1000],
    },
    label: () => i18n.t('Heart Rate Variability'),
    slug: 'heart-rate-variability',
    canBeAddedManually: false,
  },
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: {
    readingName: ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT,
    readingType: ReadingType.BLOOD_PRESSURE_SYSTOLIC,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMHG],
    color: ReadingTypeColor.bloodPressureSystolic,
    colorPalette: colors.bloodPressureSystolic,
    colorPaletteDark: colors.bloodPressureSystolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMHG]: [110, 139],
    },
    boundaryRanges: {
      [Unit.MMHG]: [0, 1390],
    },
    label: () => i18n.t('Blood Pressure Systolic'),
    slug: 'blood-pressure',
    canBeAddedManually: true,
  },
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: {
    readingName: ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT,
    readingType: ReadingType.BLOOD_PRESSURE_DIASTOLIC,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMHG],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMHG]: [70, 89],
    },
    boundaryRanges: {
      [Unit.MMHG]: [0, 890],
    },
    label: () => i18n.t('Blood Pressure Diastolic'),
    slug: 'blood-pressure',
    canBeAddedManually: true,
  },

  [ReadingName.SLEEP_SESSION_MEASUREMENT]: {
    readingName: ReadingName.SLEEP_SESSION_MEASUREMENT,
    readingType: ReadingType.SLEEP_SESSION,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.S],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.gkiGrey[200],
      withinLowerTresholdRange: colors.gkiGrey[400],
      inRange: colors.gkiGrey[400],
      withinUpperTresholdRange: colors.gkiGrey[400],
      aboveUpperTresholdRange: colors.gkiGrey[600],
    },
    defaultRanges: {
      [Unit.S]: [21600, 28800],
    },
    boundaryRanges: {
      [Unit.S]: [0, 86400],
    },
    label: () => i18n.t('Sleep'),
    labelIncludesUnit: false,
    slug: 'sleep',
    canBeAddedManually: true,
  },

  [ReadingName.INSULIN_SERUM_BLOOD]: {
    readingName: ReadingName.INSULIN_SERUM_BLOOD,
    readingType: ReadingType.INSULIN_SERUM,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PMOLL, Unit.MCUL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PMOLL]: [0, 9999],
      [Unit.MCUL]: convert.pmollToMcul([0, 9999]),
    },
    boundaryRanges: {
      [Unit.PMOLL]: [0, 20000],
      [Unit.MCUL]: convert.pmollToMcul([0, 20000]),
    },
    label: () => i18n.t('Insulin Serum'),
    slug: 'insulin-serum',
    canBeAddedManually: true,
  },
  [ReadingName.INSULIN_BASAL_MDI]: {
    readingName: ReadingName.INSULIN_BASAL_MDI,
    readingType: ReadingType.INSULIN_BASAL,
    readingSampleType: ReadingSampleType.MDI,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.IU],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.IU]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.IU]: [0, 10000],
    },
    label: () => i18n.t('Insulin Basal'),
    slug: 'insulin-basal',
    canBeAddedManually: true,
  },
  [ReadingName.INSULIN_BOLUS_MDI]: {
    readingName: ReadingName.INSULIN_BOLUS_MDI,
    readingType: ReadingType.INSULIN_BOLUS,
    readingSampleType: ReadingSampleType.MDI,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.IU],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.IU]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.IU]: [0, 10000],
    },
    label: () => i18n.t('Insulin Bolus'),
    slug: 'insulin-bolus',
    canBeAddedManually: true,
  },
  [ReadingName.HDL_BLOOD]: {
    readingName: ReadingName.HDL_BLOOD,
    readingType: ReadingType.HDL,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMOLL, Unit.MGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMOLL]: [0, 9999],
      [Unit.MGDL]: convert.ldlHdlMmollToMgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MMOLL]: [0, 1],
      [Unit.MGDL]: convert.ldlHdlMmollToMgdl([0, 1]),
    },
    label: () => i18n.t('HDL'),
    slug: 'hdl',
    canBeAddedManually: true,
  },
  [ReadingName.LDL_BLOOD]: {
    readingName: ReadingName.LDL_BLOOD,
    readingType: ReadingType.LDL,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMOLL, Unit.MGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMOLL]: [0, 9999],
      [Unit.MGDL]: convert.ldlHdlMmollToMgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MMOLL]: [0, 26],
      [Unit.MGDL]: convert.ldlHdlMmollToMgdl([0, 26]),
    },
    label: () => i18n.t('LDL'),
    slug: 'ldl',
    canBeAddedManually: true,
  },
  [ReadingName.TRIGLYCERIDES_BLOOD]: {
    readingName: ReadingName.TRIGLYCERIDES_BLOOD,
    readingType: ReadingType.TRIGLYCERIDES,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MMOLL, Unit.MGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MMOLL]: [0, 9999],
      [Unit.MGDL]: convert.triglyceridesMmollToMgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MMOLL]: [0, 17],
      [Unit.MGDL]: convert.triglyceridesMmollToMgdl([0, 17]),
    },
    label: () => i18n.t('Triglycerides'),
    slug: 'triglycerides',
    canBeAddedManually: true,
  },
  [ReadingName.HBA1C_BLOOD]: {
    readingName: ReadingName.HBA1C_BLOOD,
    readingType: ReadingType.HBA1C,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PERCENTAGE, Unit.MMOL_MOL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PERCENTAGE]: [0, 9999],
      [Unit.MMOL_MOL]: convert.percentageToMmolmol([0, 9999]),
    },
    boundaryRanges: {
      [Unit.PERCENTAGE]: [2.2, 100],
      [Unit.MMOL_MOL]: convert.percentageToMmolmol([2.2, 100]),
    },
    label: () => i18n.t('HbA1c'),
    slug: 'hba1c',
    canBeAddedManually: true,
  },
  [ReadingName.APOB_BLOOD]: {
    readingName: ReadingName.APOB_BLOOD,
    readingType: ReadingType.APOB,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL, Unit.GL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
      [Unit.GL]: convert.mgdlToGl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 900],
      [Unit.GL]: convert.mgdlToGl([0, 900]),
    },
    label: () => i18n.t('ApoB'),
    slug: 'apob',
    canBeAddedManually: true,
  },
  [ReadingName.APOAL_BLOOD]: {
    readingName: ReadingName.APOAL_BLOOD,
    readingType: ReadingType.APOAL,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL, Unit.GL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
      [Unit.GL]: convert.mgdlToGl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 10000000],
      [Unit.GL]: convert.mgdlToGl([0, 10000000]),
    },
    label: () => i18n.t('ApoA-L'),
    slug: 'apoal',
    canBeAddedManually: true,
  },
  [ReadingName.T3_BLOOD]: {
    readingName: ReadingName.T3_BLOOD,
    readingType: ReadingType.T3,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.NMOLL, Unit.NGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.NMOLL]: [0, 9999],
      [Unit.NGDL]: convert.nmollToNgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.NMOLL]: [0, 9999],
      [Unit.NGDL]: convert.nmollToNgdl([0, 9999]),
    },
    label: () => i18n.t('T3'),
    slug: 't3',
    canBeAddedManually: true,
  },
  [ReadingName.FREE_T3_BLOOD]: {
    readingName: ReadingName.FREE_T3_BLOOD,
    readingType: ReadingType.FREE_T3,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PMOLL, Unit.PGML],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PMOLL]: [0, 9999],
      [Unit.PGML]: convert.pmollToPgml([0, 9999]),
    },
    boundaryRanges: {
      [Unit.PMOLL]: [0, 9999],
      [Unit.PGML]: convert.pmollToPgml([0, 9999]),
    },
    label: () => i18n.t('Free T3'),
    slug: 'free-t3',
    canBeAddedManually: true,
  },
  [ReadingName.T4_BLOOD]: {
    readingName: ReadingName.T4_BLOOD,
    readingType: ReadingType.T4,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.NMOLL, Unit.MCGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.NMOLL]: [0, 9999],
      [Unit.MCGDL]: convert.nmollToMcgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.NMOLL]: [0, 9999],
      [Unit.MCGDL]: convert.nmollToMcgdl([0, 9999]),
    },
    label: () => i18n.t('T4'),
    slug: 't4',
    canBeAddedManually: true,
  },
  [ReadingName.FREE_T4_BLOOD]: {
    readingName: ReadingName.FREE_T4_BLOOD,
    readingType: ReadingType.FREE_T4,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PMOLL, Unit.NGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PMOLL]: [0, 9999],
      [Unit.NGDL]: convert.pmollToNgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.PMOLL]: [0, 9999],
      [Unit.NGDL]: convert.pmollToNgdl([0, 9999]),
    },
    label: () => i18n.t('Free T4'),
    slug: 'free-t4',
    canBeAddedManually: true,
  },
  [ReadingName.TSH_BLOOD]: {
    readingName: ReadingName.TSH_BLOOD,
    readingType: ReadingType.TSH,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MLUL, Unit.MCUML],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MLUL]: [0, 9999],
      [Unit.MCUML]: convert.mlulTomcUml([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MLUL]: [0, 9999],
      [Unit.MCUML]: convert.mlulTomcUml([0, 9999]),
    },
    label: () => i18n.t('TSH'),
    slug: 'tsh',
    canBeAddedManually: true,
  },
  [ReadingName.CARNITINE_BLOOD]: {
    readingName: ReadingName.CARNITINE_BLOOD,
    readingType: ReadingType.CARNITINE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MCMOLL, Unit.MGDL],
    color: ReadingTypeColor.bloodPressureDiastolic,
    colorPalette: colors.bloodPressureDiastolic,
    colorPaletteDark: colors.bloodPressureDiastolicDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MCMOLL]: [0, 9999],
      [Unit.MGDL]: convert.mcmollToMgdl([0, 9999]),
    },
    boundaryRanges: {
      [Unit.MCMOLL]: [0, 9999],
      [Unit.MGDL]: convert.mcmollToMgdl([0, 9999]),
    },
    label: () => i18n.t('Carnitine'),
    slug: 'carnitine',
    canBeAddedManually: true,
  },

  [ReadingName.HSCRP_BLOOD]: {
    readingName: ReadingName.HSCRP_BLOOD,
    readingType: ReadingType.HSCRP,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGL]: [0, 9999],
    },
    label: () => i18n.t('hsCRP'),
    slug: 'hscrp',
    canBeAddedManually: true,
  },
  [ReadingName.ALANINE_AMINOTRANSFERASE_BLOOD]: {
    readingName: ReadingName.ALANINE_AMINOTRANSFERASE_BLOOD,
    readingType: ReadingType.ALANINE_AMINOTRANSFERASE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.UL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.UL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.UL]: [0, 9999],
    },
    label: () => i18n.t('Alanine Aminotransferase (ALT)'),
    slug: 'alanine-aminotransferase',
    canBeAddedManually: true,
  },
  [ReadingName.ALBUMIN_BLOOD]: {
    readingName: ReadingName.ALBUMIN_BLOOD,
    readingType: ReadingType.ALBUMIN,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.GDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.GDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.GDL]: [0, 9999],
    },
    label: () => i18n.t('Albumin'),
    slug: 'albumin',
    canBeAddedManually: true,
  },
  [ReadingName.ALKALINE_PHOSPHATASE_BLOOD]: {
    readingName: ReadingName.ALKALINE_PHOSPHATASE_BLOOD,
    readingType: ReadingType.ALKALINE_PHOSPHATASE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.UL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.UL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.UL]: [0, 9999],
    },
    label: () => i18n.t('Alkaline Phosphatase (ALP)'),
    slug: 'alkaline-phosphatase',
    canBeAddedManually: true,
  },
  [ReadingName.ASPARTATE_AMINOTRANSFERASE_BLOOD]: {
    readingName: ReadingName.ASPARTATE_AMINOTRANSFERASE_BLOOD,
    readingType: ReadingType.ASPARTATE_AMINOTRANSFERASE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.UL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.UL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.UL]: [0, 9999],
    },
    label: () => i18n.t('Aspartate Aminotransferase (AST)'),
    slug: 'aspartate-aminotransferase',
    canBeAddedManually: true,
  },
  [ReadingName.BICARBONATE_BLOOD]: {
    readingName: ReadingName.BICARBONATE_BLOOD,
    readingType: ReadingType.BICARBONATE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MEQL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    label: () => i18n.t('Bicarbonate'),
    slug: 'bicarbonate',
    canBeAddedManually: true,
  },
  [ReadingName.BILIRUBIN_BLOOD]: {
    readingName: ReadingName.BILIRUBIN_BLOOD,
    readingType: ReadingType.BILIRUBIN,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    label: () => i18n.t('Bilirubin'),
    slug: 'bilirubin',
    canBeAddedManually: true,
  },
  [ReadingName.BLOOD_UREA_NITROGEN_BLOOD]: {
    readingName: ReadingName.BLOOD_UREA_NITROGEN_BLOOD,
    readingType: ReadingType.BLOOD_UREA_NITROGEN,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    label: () => i18n.t('Blood Urea Nitrogen (BUN)'),
    slug: 'blood-urea-nitrogen',
    canBeAddedManually: true,
  },
  [ReadingName.CALCIUM_BLOOD]: {
    readingName: ReadingName.CALCIUM_BLOOD,
    readingType: ReadingType.CALCIUM,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    label: () => i18n.t('Calcium'),
    slug: 'calcium',
    canBeAddedManually: true,
  },
  [ReadingName.CHLORIDE_BLOOD]: {
    readingName: ReadingName.CHLORIDE_BLOOD,
    readingType: ReadingType.CHLORIDE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    label: () => i18n.t('Chloride'),
    slug: 'chloride',
    canBeAddedManually: true,
  },
  [ReadingName.CREATININE_BLOOD]: {
    readingName: ReadingName.CREATININE_BLOOD,
    readingType: ReadingType.CREATININE,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MGDL]: [0, 9999],
    },
    label: () => i18n.t('Creatinine'),
    slug: 'creatinine',
    canBeAddedManually: true,
  },
  [ReadingName.POTASSIUM_BLOOD]: {
    readingName: ReadingName.POTASSIUM_BLOOD,
    readingType: ReadingType.POTASSIUM,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MEQL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    label: () => i18n.t('Potassium Blood'),
    slug: 'potassium-blood',
    canBeAddedManually: true,
  },
  [ReadingName.SODIUM_BLOOD]: {
    readingName: ReadingName.SODIUM_BLOOD,
    readingType: ReadingType.SODIUM,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MEQL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MEQL]: [0, 9999],
    },
    label: () => i18n.t('Sodium'),
    slug: 'sodium',
    canBeAddedManually: true,
  },
  [ReadingName.TOTAL_PROTEIN_BLOOD]: {
    readingName: ReadingName.TOTAL_PROTEIN_BLOOD,
    readingType: ReadingType.TOTAL_PROTEIN,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.GDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.GDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.GDL]: [0, 9999],
    },
    label: () => i18n.t('Total Protein'),
    slug: 'total-protein',
    canBeAddedManually: true,
  },
  [ReadingName.COPPER_BLOOD]: {
    readingName: ReadingName.COPPER_BLOOD,
    readingType: ReadingType.COPPER,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.MCGDL],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.MCGDL]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.MCGDL]: [0, 9999],
    },
    label: () => i18n.t('Copper'),
    slug: 'copper',
    canBeAddedManually: true,
  },
  [ReadingName.TNFA_BLOOD]: {
    readingName: ReadingName.TNFA_BLOOD,
    readingType: ReadingType.TNFA,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PGML],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PGML]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.PGML]: [0, 9999],
    },
    label: () => i18n.t('TNF-α'),
    slug: 'tnfa',
    canBeAddedManually: true,
  },
  [ReadingName.IL6_BLOOD]: {
    readingName: ReadingName.IL6_BLOOD,
    readingType: ReadingType.IL6,
    readingSampleType: ReadingSampleType.BLOOD,
    category: ReadingCategory.LAB,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PGML],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PGML]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.PGML]: [0, 9999],
    },
    label: () => i18n.t('IL-6'),
    slug: 'il6',
    canBeAddedManually: true,
  },
  [ReadingName.BONE_MASS_MEASUREMENT]: {
    readingName: ReadingName.BONE_MASS_MEASUREMENT,
    readingType: ReadingType.BONE_MASS,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    label: () => i18n.t('Bone Mass'),
    slug: 'bone-mass',
    canBeAddedManually: true,
  },
  [ReadingName.FAT_FREE_MASS_MEASUREMENT]: {
    readingName: ReadingName.FAT_FREE_MASS_MEASUREMENT,
    readingType: ReadingType.FAT_FREE_MASS,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    label: () => i18n.t('Fat Free Mass'),
    slug: 'fat-free-mass',
    canBeAddedManually: true,
  },
  [ReadingName.VISCERAL_FAT_MEASUREMENT]: {
    readingName: ReadingName.VISCERAL_FAT_MEASUREMENT,
    readingType: ReadingType.VISCERAL_FAT,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    label: () => i18n.t('Visceral Fat'),
    slug: 'visceral-fat',
    canBeAddedManually: true,
  },
  [ReadingName.VISCERAL_FAT_PERCENTAGE_MEASUREMENT]: {
    readingName: ReadingName.VISCERAL_FAT_PERCENTAGE_MEASUREMENT,
    readingType: ReadingType.VISCERAL_FAT_PERCENTAGE,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PERCENTAGE],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PERCENTAGE]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.PERCENTAGE]: [0, 100],
    },
    label: () => i18n.t('Visceral Fat Percentage'),
    slug: 'visceral-fat-percentage',
    canBeAddedManually: true,
  },
  [ReadingName.ABSOLUTE_FAT_MEASUREMENT]: {
    readingName: ReadingName.ABSOLUTE_FAT_MEASUREMENT,
    readingType: ReadingType.ABSOLUTE_FAT,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    label: () => i18n.t('Absolute Fat'),
    slug: 'absolute-fat',
    canBeAddedManually: true,
  },
  [ReadingName.ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT]: {
    readingName: ReadingName.ABSOLUTE_FAT_PERCENTAGE_MEASUREMENT,
    readingType: ReadingType.ABSOLUTE_FAT_PERCENTAGE,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PERCENTAGE],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PERCENTAGE]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.PERCENTAGE]: [0, 100],
    },
    label: () => i18n.t('Absolute Fat Percentage'),
    slug: 'absolute-fat-percentage',
    canBeAddedManually: true,
  },
  [ReadingName.MUSCLE_MASS_MEASUREMENT]: {
    readingName: ReadingName.MUSCLE_MASS_MEASUREMENT,
    readingType: ReadingType.MUSCLE_MASS,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.KG, Unit.LBS],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    boundaryRanges: {
      [Unit.KG]: [0, 9999],
      [Unit.LBS]: convert.kgToLbs([0, 9999]),
    },
    label: () => i18n.t('Muscle Mass'),
    slug: 'muscle-mass',
    canBeAddedManually: true,
  },
  [ReadingName.MUSCLE_MASS_PERCENTAGE_MEASUREMENT]: {
    readingName: ReadingName.MUSCLE_MASS_PERCENTAGE_MEASUREMENT,
    readingType: ReadingType.MUSCLE_MASS_PERCENTAGE,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.PERCENTAGE],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.PERCENTAGE]: [0, 9999],
    },
    boundaryRanges: {
      [Unit.PERCENTAGE]: [0, 100],
    },
    label: () => i18n.t('Muscle Mass Percentage'),
    slug: 'muscle-mass-percentage',
    canBeAddedManually: true,
  },
  [ReadingName.ACTIVITY_LEVEL_MEASUREMENT]: {
    readingName: ReadingName.ACTIVITY_LEVEL_MEASUREMENT,
    readingType: ReadingType.ACTIVITY_LEVEL,
    readingSampleType: ReadingSampleType.MEASUREMENT,
    category: ReadingCategory.BIOMETRICS,
    entryTypes: [ReadingEntryType.MANUAL],
    units: [Unit.NO_UNIT],
    color: ReadingTypeColor.weight,
    colorPalette: colors.weight,
    colorPaletteDark: colors.weightDark,
    rangeColorPalette: {
      belowLowerTresholdRange: colors.red[700],
      withinLowerTresholdRange: colors.orange[400],
      inRange: colors.coolGray[400],
      withinUpperTresholdRange: colors.orange[400],
      aboveUpperTresholdRange: colors.red[700],
    },
    defaultRanges: {
      [Unit.NO_UNIT]: [1, 5],
    },
    boundaryRanges: {
      [Unit.NO_UNIT]: [1, 50],
    },
    label: () => i18n.t('Activity Level'),
    slug: 'activity-level',
    canBeAddedManually: false,
  },
};

export type DomainConfigItem = typeof domainConfig[keyof typeof domainConfig];
