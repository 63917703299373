export enum LocalStorageKey {
  REGIONS_METADATA = 'all_regions_metadata',
  COOKIE_CONSENT = 'cookie_consent',
  DARK_MODE = 'dark_mode',
  DASHBOARD_TRENDS_TOGGLE = 'dashboard_trends_toggle',
  DID_HAVE_ACTIVE_SESSION = 'did_have_active_session',
  LANGUAGE_CODE = 'i18nextLng',
  PRACTICE_TRENDS_TOGGLE = 'practice_trends_toggle',
  REGION = 'region',
  EXTERNAL_EHR_CONNECTED_NOTIFICATION_DISPLAYED = 'external_ehr_connected_toast_displayed',
  FEATURE_FLAGS = 'feature_flags',
  RECENT_PRACTICE_ID = 'recent_practice_id',
  RECENT_PRACTICE_REPORT_TYPE = 'recent_practice_report_type',
  LAST_VISITED_PAGE = 'last_visited_page',
  USER_PATIENTS_TABLE_STATE = 'user_patients_table_state',
  HCP_PATIENTS_TABLE_STATE = 'hcp_patients_table_state',
  PRACTICE_REPORT_STATE = 'practice_report_state',
  USER_PRACTICES_TABLE_STATE = 'user_practices_table_state',
  HCP_PRACTICES_TABLE_STATE = 'hcp_practices_table_state',
  READINGS_TABLE_STATE = 'readings_table_state',
  PRACTICE_DIRECTORY_TABLE_STATE = 'practice_directory_table_state',
  READINGS_TABLE_FILTER_STATE = 'readings_table_filter_state',
  APP_VERSION = 'app_version',
  READING_NAMES_FILTER_STATE = 'reading_names_filter_state',
  DASHBOARD_REPORT_STATE = 'dashboard_report_state',
  EU_STORE_PRICE_NOTIFICATION_DISPLAYED = 'eu_store_price_notification_displayed',
  OAUTH_SCOPES = 'oauth_scopes',
  DATE_RANGE_FILTER = 'date_range_filter',
  MACROS_APP = 'macros_app',
}

type AppVersion = string;
type Variant = string;
export type VersionedLocalStorageKey =
  `${LocalStorageKey}_${Variant}_${AppVersion}`;
