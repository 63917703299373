import {
  calculatorConsts,
  genders,
  modes,
  primaryHealthGoals,
  steps,
} from './config/defaults';

export type Gender = typeof genders[keyof typeof genders] | undefined;

export type Step = typeof steps[number] | undefined;

export type PrimaryHealthGoal = keyof typeof primaryHealthGoals;

export type RecommendedDailyProteinIntakeChoice = 'suggested' | 'custom';
export type RecommendedDailyCarbsIntakeChoice = 'suggested' | 'custom';

export enum ActivityLevel {
  sedentary = 1,
  lightly_active = 2,
  moderately_active = 3,
  very_active = 4,
  athlete_bodybuilder = 5,
}

export type ProteinData = {
  min: number;
  min_g_per_kg: number;
  min_g_per_lb: number;
  max: number;
  max_g_per_kg: number;
  max_g_per_lb: number;
  chosen: number;
  chosen_g_per_kg: number;
  chosen_g_per_lb: number;
  suggested: number;
};

export type CarbsData = {
  max: number;
  min: number;
  suggested: number;
  chosen: number;
  net: boolean;
};

export enum MeasureSystem {
  Imperial = 'imperial',
  Metric = 'metric',
}

export enum BfiLabel {
  Lean = 'lean',
  Athletic = 'athletic',
  Average = 'average',
  Overweight = 'overweight',
  Custom = 'custom',
}

export type FatData = {
  chosen: number;
  chosen_g: number;
  chosen_kcal: number;

  min_g: number;
  min_kcal: number;

  maintenance_g: number;
  maintenance_kcal: number;

  max_g: number;
  max_kcal: number;
};

export type ActivityLevelValue =
  keyof typeof calculatorConsts.settings.activityLevels;

export type Mode = typeof modes[number];

export type Bfi = {
  value: string | undefined;
  // TODO: fix types
  // label: BfiLabel;
  label: string;
  min: number;
  max: number;
  custom?: string;
};
