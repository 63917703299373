import { ReactNode, VFC } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import Button from 'components/Button/Button';
import { Mode } from 'features/macros-app/types';

import { ModalCloseButton } from './shared/modal-close-button';
import { ModalDescription } from './shared/modal-description';
import { ModalTitle } from './shared/modal-title';
import { ProgressBar } from './modal-progress-bar';

interface ModalHeaderProps {
  title: ReactNode;
  className?: string;
  description?: ReactNode;
  isMultistep?: boolean;
  currentStep?: number;
  maxSteps?: number;
  mode?: Mode;
  onClickPrev?: () => void;
}

export const ModalHeader: VFC<ModalHeaderProps> = ({
  className,
  title,
  description,
  isMultistep = false,
  maxSteps,
  currentStep,
  mode = 'calculator',
  onClickPrev,
}) => (
  <>
    <header className={clsx('mb-12', className)}>
      <div className="mb-5 flex items-start justify-between">
        {isMultistep ? (
          <Button
            className={clsx(
              '!rounded-full text-ketoneBlue dark:!text-ketoneBlue-400 sm:!bg-ketoneBlue-50 sm:dark:!bg-ketoneBlue-900',
              currentStep === 0 ? 'sm:invisible' : ''
            )}
            onClick={onClickPrev}
          >
            <ArrowLeftIcon />
          </Button>
        ) : null}
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton className="ml-4" />
      </div>
      {description && <ModalDescription>{description}</ModalDescription>}
    </header>
    {isMultistep && mode === 'calculator' ? (
      <ProgressBar maxSteps={maxSteps} currentStep={currentStep} />
    ) : null}
  </>
);
