import { User } from 'shared/user/types';

import { useMacrosAppStore } from 'features/macros-app/store/macros-app.store';
import { Unit } from 'models';

import { primaryHealthGoals } from '../config/defaults';
import {
  calculateFatFromMinMax,
  convertInchesToFeet,
  getBfiData,
} from '../utils/calculator';

export const usePopulateMacrosStore = () => {
  const [
    ,
    {
      setGender,
      setAge,
      setHeight,
      setWeight,
      setPrimaryHealthGoal,
      setCarbsData,
      setProteinData,
      setFatData,
      setBfi,
      setBmr,
      setHeightUnit,
      setWeightUnit,
      setCompleted,
    },
  ] = useMacrosAppStore();

  const mapData = (user: User) => {
    if (user.sex) {
      setGender(user.sex, true);
    }

    if (user.age != null) {
      setAge(user.age, true);
    }

    if (user.height != null) {
      if (user.defaultHeightUnit === Unit.CM) {
        setHeight({ heightCm: user.height }, true);
      }
      if (user.defaultHeightUnit === Unit.INCH) {
        const { feet, inches } = convertInchesToFeet(user.height);
        setHeight({ heightFeet: feet, heightInch: inches }, true);
      }
    }

    if (user.defaultHeightUnit) {
      setHeightUnit(user.defaultHeightUnit, true);
    }

    if (
      user.weightKgMax != null &&
      user.weightLbsMax != null &&
      user.defaultWeightUnit
    ) {
      setWeight(
        user.defaultWeightUnit === Unit.KG
          ? user.weightKgMax
          : user.weightLbsMax,
        true
      );
    }

    if (user.defaultWeightUnit) {
      setWeightUnit(user.defaultWeightUnit, true);
    }

    if (user.primaryHealthGoal) {
      setPrimaryHealthGoal(
        {
          label: primaryHealthGoals[user.primaryHealthGoal],
          value: user.primaryHealthGoal,
        },
        true
      );
    }

    if (user.carbohydratesGMax != null) {
      setCarbsData(
        {
          chosen: user.carbohydratesGMax,
        },
        true
      );
    }

    if (user.proteinGMin != null) {
      setProteinData(
        {
          chosen: user.proteinGMin,
        },
        true
      );
    }

    if (user.fatGMin != null && user.fatGMax != null) {
      const fatG = calculateFatFromMinMax(user.fatGMin, user.fatGMax);

      setFatData(
        {
          chosen_g: fatG,
        },
        true
      );
    }

    if (user.absoluteFatPercentageMin != null) {
      const { value, label } = getBfiData({
        min: user.absoluteFatPercentageMin,
        max: user.absoluteFatPercentageMax,
      });
      setBfi(
        {
          value,
          label,
        },
        true
      );
    }

    if (user.bmrMax != null) {
      setBmr(user.bmrMax, true);
    }

    if (user.macroCalculatorWizardCompleted) {
      setCompleted(user.macroCalculatorWizardCompleted);
    }
  };

  return {
    mapData,
  };
};
