export enum ReadingSource {
  SERVER_CALCULATED_GKI = 'server_calculated_gki',
  SERVER_DYNAMICALLY_CALCULATED_GKI = 'server_dynamically_calculated_gki',
  DEVICE = 'device',
  DEVICE_CALCULATED_GKI = 'device_calculated_gki',
  USER_ENTERED = 'user_entered',
  APPLE_HEALTH = 'apple_health',
  GOOGLE_FIT = 'google_fit',
  SAMSUNG_FIT = 'samsung_fit',
  ANDROID_HEALTH = 'android_health',
  SAMSUNG_HEALTH = 'samsung_health',
  DEXCOM = 'dexcom',
  WITHINGS = 'withings',
  OURA = 'oura',
  FITBIT = 'fitbit',
  CRONOMETER = 'cronometer',
}

export type ExternalReadingSource =
  | ReadingSource.APPLE_HEALTH
  | ReadingSource.GOOGLE_FIT
  | ReadingSource.SAMSUNG_FIT
  | ReadingSource.ANDROID_HEALTH
  | ReadingSource.SAMSUNG_HEALTH
  | ReadingSource.DEXCOM
  | ReadingSource.WITHINGS
  | ReadingSource.OURA
  | ReadingSource.FITBIT
  | ReadingSource.CRONOMETER;

export const isExternalReadingSource = (
  source: string
): source is ExternalReadingSource =>
  [
    ReadingSource.APPLE_HEALTH,
    ReadingSource.GOOGLE_FIT,
    ReadingSource.SAMSUNG_FIT,
    ReadingSource.ANDROID_HEALTH,
    ReadingSource.SAMSUNG_HEALTH,
    ReadingSource.DEXCOM,
    ReadingSource.WITHINGS,
    ReadingSource.OURA,
    ReadingSource.FITBIT,
    ReadingSource.CRONOMETER,
  ].includes(source as ReadingSource);
