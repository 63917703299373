import {
  Action,
  ContainerComponent,
  createContainer,
  createHook,
  createStore,
  defaults,
} from 'react-sweet-state';
import { round } from 'lodash';
import { localStorage, storeSideEffectMiddleware } from 'shared/common/utils';

import { LocalStorageKey } from 'consts';
import {
  HeightMeasurementUnit,
  WeightMeasurementUnit,
} from 'domain/domain.models';
import { Unit } from 'models';

import { calculatorConsts } from '../config/defaults';
import {
  ActivityLevel,
  ActivityLevelValue,
  Bfi,
  BfiLabel,
  CarbsData,
  FatData,
  Gender,
  MeasureSystem,
  Mode,
  PrimaryHealthGoal,
  ProteinData,
  RecommendedDailyCarbsIntakeChoice,
  RecommendedDailyProteinIntakeChoice,
  Step,
} from '../types';
import {
  calculateBfi,
  calculateChosenKCalFromFatMaintenance,
  calculateDeficit,
  calculateHeightMeasures,
  calculateWeight,
  convertCmToInches,
  getBfiData,
} from '../utils/calculator';

type State = {
  gender: Gender;
  age: number | undefined;
  heightCm: number | undefined;
  heightFeet: number | undefined;
  heightInch: number | undefined;
  heightTotalInches: number | undefined;
  heightUnit: HeightMeasurementUnit;
  weightKg: number | undefined;
  weightLbs: number | undefined;
  weightUnit: WeightMeasurementUnit;
  activityLevel: {
    index: ActivityLevel;
    value: ActivityLevelValue;
    label: string;
  };
  accordingActivity: number;
  bfi: Bfi;
  primaryHealthGoal: {
    label: string | undefined;
    value: PrimaryHealthGoal | undefined;
  };
  recommendedDailyCarbsIntakeChoice:
    | RecommendedDailyCarbsIntakeChoice
    | undefined;
  recommendedDailyProteinIntakeChoice:
    | RecommendedDailyProteinIntakeChoice
    | undefined;
  bmi: number;
  bmr: number | undefined;
  proteinData: ProteinData;
  fatData: FatData;
  measureSystem: MeasureSystem;
  carbsData: CarbsData;
  completed: boolean;
  currentStep: Step;
  mode: Mode;
  // serverData is used as a backup for macros settings view
  serverData:
    | {
        gender?: State['gender'];
        age?: State['age'];
        heightCm?: State['heightCm'];
        heightFeet?: State['heightFeet'];
        heightInch?: State['heightInch'];
        heightTotalInches?: State['heightTotalInches'];
        heightUnit?: State['heightUnit'];
        weightUnit?: State['weightUnit'];
        weightKg?: State['weightKg'];
        weightLbs?: State['weightLbs'];
        primaryHealthGoal?: State['primaryHealthGoal'];
        carbsData?: State['carbsData'];
        proteinData?: State['proteinData'];
        fatData?: State['fatData'];
        bfi?: State['bfi'];
        bmr?: State['bmr'];
        activityLevel?: State['activityLevel'];
      }
    | undefined;
  openModalOnMount: boolean;
};

const STORE_NAME = 'macrosCalculator';

const actions = {
  setGender:
    (gender: State['gender'], isSetServerData?: boolean): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ gender });

      if (isSetServerData) {
        dispatch(actions.setServerData({ gender }));
      }
    },
  setAge:
    (age: State['age'], isSetServerData?: boolean): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ age });

      if (isSetServerData) {
        dispatch(actions.setServerData({ age }));
      }
    },
  setHeight:
    (
      {
        heightCm,
        heightFeet,
        heightInch,
      }: {
        heightCm?: number;
        heightFeet?: number;
        heightInch?: number;
      },
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, getState, dispatch }) => {
      // TODO: simplify
      if (heightCm != null) {
        const { cm, feet, inches } = calculateHeightMeasures(
          heightCm!,
          Unit.CM
        );

        const heightTotalInches = round(convertCmToInches(cm), 0);

        setState({
          heightCm: cm,
          heightFeet: feet,
          heightInch: inches,
          heightTotalInches,
        });

        if (isSetServerData) {
          dispatch(
            actions.setServerData({
              heightCm: cm,
              heightFeet: feet,
              heightInch: inches,
              heightTotalInches,
            })
          );
        }

        return;
      } else if (heightFeet != null || heightInch != null) {
        const newHeightFeet = heightFeet ?? getState().heightFeet ?? 0;
        const newHeightInch = heightInch ?? getState().heightInch ?? 0;

        // prevent calculations if user sets inches to more than 11
        if (newHeightInch > 11) {
          return;
        }

        const heightTotalInches = round(
          newHeightFeet * calculatorConsts.convertFeetToInches + newHeightInch,
          1
        );
        const { cm, feet, inches } = calculateHeightMeasures(
          heightTotalInches,
          Unit.INCH
        );

        setState({
          heightCm: cm,
          heightFeet: feet,
          heightInch: inches,
          heightTotalInches,
        });

        if (isSetServerData) {
          dispatch(
            actions.setServerData({
              heightCm: cm,
              heightFeet: feet,
              heightInch: inches,
              heightTotalInches,
            })
          );
        }

        return;
      }

      setState({
        heightCm: 0,
        heightFeet: 0,
        heightInch: 0,
      });
    },
  setHeightUnit:
    (
      heightUnit: State['heightUnit'],
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ heightUnit });

      heightUnit === Unit.CM
        ? dispatch(actions.setMeasureSystem(MeasureSystem.Metric))
        : dispatch(actions.setMeasureSystem(MeasureSystem.Imperial));

      if (isSetServerData) {
        dispatch(actions.setServerData({ heightUnit }));
      }
    },
  setWeight:
    (weight: number, isSetServerData?: boolean): Action<State> =>
    ({ getState, setState, dispatch }) => {
      const { kg, lbs } = calculateWeight(weight, getState().weightUnit);

      setState({
        weightKg: kg,
        weightLbs: lbs,
      });

      if (isSetServerData) {
        dispatch(actions.setServerData({ weightKg: kg, weightLbs: lbs }));
      }
    },
  setWeightUnit:
    (
      weightUnit: State['weightUnit'],
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ weightUnit });

      weightUnit === Unit.KG
        ? dispatch(actions.setMeasureSystem(MeasureSystem.Metric))
        : dispatch(actions.setMeasureSystem(MeasureSystem.Imperial));

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            weightUnit,
          })
        );
      }
    },
  setActivityLevel:
    (
      activityLevel: State['activityLevel'],
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ activityLevel });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            activityLevel,
          })
        );
      }
    },
  setAccordingActivity:
    (accordingActivity: State['accordingActivity']): Action<State> =>
    ({ setState }) =>
      setState({ accordingActivity }),
  setBfi:
    (
      bfi: Omit<State['bfi'], 'min' | 'max'>,
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, getState, dispatch }) => {
      const { min, max, value } = getBfiData({ value: bfi.value as string });

      const customBfi =
        getState().bfi.custom ||
        calculateBfi(
          getState().gender,
          getState().heightCm!,
          getState().weightKg!,
          getState().age!
        ).toString();

      if (bfi.label !== 'custom') {
        const newBfi = { ...bfi, min, max, custom: customBfi };
        setState({ bfi: newBfi });

        if (isSetServerData) {
          dispatch(
            actions.setServerData({
              bfi: newBfi,
            })
          );
        }
      } else {
        const newBfi = {
          ...bfi,
          value: isNaN(+bfi.value!)
            ? getState().bfi.custom
            : round(+bfi.value!).toString(),
          min,
          max,
          custom: isNaN(+value!)
            ? getState().bfi.custom
            : round(+bfi.value!).toString(),
        };

        setState({
          bfi: newBfi,
        });

        if (isSetServerData) {
          dispatch(
            actions.setServerData({
              bfi: newBfi,
            })
          );
        }
      }
    },
  setPrimaryHealthGoal:
    (
      primaryHealthGoal: State['primaryHealthGoal'],
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ primaryHealthGoal });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            primaryHealthGoal,
          })
        );
      }
    },
  setRecommendedDailyCarbsIntakeChoice:
    (
      recommendedDailyCarbsIntakeChoice: State['recommendedDailyCarbsIntakeChoice']
    ): Action<State> =>
    ({ setState }) =>
      setState({ recommendedDailyCarbsIntakeChoice }),
  setRecommendedDailyProteinIntakeChoice:
    (
      recommendedDailyProteinIntakeChoice: State['recommendedDailyProteinIntakeChoice']
    ): Action<State> =>
    ({ setState }) =>
      setState({ recommendedDailyProteinIntakeChoice }),
  setBmi:
    (bmi: State['bmi']): Action<State> =>
    ({ setState }) =>
      setState({ bmi }),
  setBmr:
    (bmr: State['bmr'], isSetServerData?: boolean): Action<State> =>
    ({ setState, dispatch }) => {
      setState({ bmr });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            bmr,
          })
        );
      }
    },
  setProteinData:
    (
      proteinData: Partial<ProteinData> | undefined,
      isSetServerData?: boolean
    ): Action<State> =>
    ({ setState, getState, dispatch }) => {
      const newProteinData = { ...getState().proteinData, ...proteinData };
      setState({ proteinData: newProteinData });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            proteinData: newProteinData,
          })
        );
      }
    },
  setFatData:
    (fatData: Partial<FatData>, isSetServerData?: boolean): Action<State> =>
    ({ setState, getState, dispatch }) => {
      const newFatData = { ...getState().fatData, ...fatData };

      const deficit = calculateDeficit(
        newFatData.maintenance_g,
        newFatData.chosen_g!
      );

      const chosenKcal = calculateChosenKCalFromFatMaintenance(
        deficit,
        fatData.maintenance_kcal!
      );

      newFatData.chosen = deficit;
      newFatData.chosen_kcal = chosenKcal || getState().fatData.chosen_kcal;

      setState({ fatData: newFatData });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            fatData: newFatData,
          })
        );
      }
    },
  setMeasureSystem:
    (measureSystem: State['measureSystem']): Action<State> =>
    ({ setState }) =>
      setState({ measureSystem }),
  setCarbsData:
    (carbsData: Partial<CarbsData>, isSetServerData?: boolean): Action<State> =>
    ({ setState, getState, dispatch }) => {
      const newCarbsData = { ...getState().carbsData, ...carbsData };
      setState({ carbsData: newCarbsData });

      if (isSetServerData) {
        dispatch(
          actions.setServerData({
            carbsData: newCarbsData,
          })
        );
      }
    },
  setCompleted:
    (completed: State['completed']): Action<State> =>
    ({ setState }) =>
      setState({ completed }),
  setCurrentStep:
    (currentStep: State['currentStep']): Action<State> =>
    ({ setState }) =>
      setState({ currentStep }),
  setMode:
    (mode: State['mode']): Action<State> =>
    ({ setState, getState }) => {
      mode === 'calculator'
        ? setState({ mode, currentStep: getState().currentStep ?? 'gender' })
        : setState({ mode, currentStep: undefined });
    },
  setServerData:
    (serverData: Partial<State['serverData']>): Action<State> =>
    ({ setState, getState }) => {
      setState({ serverData: { ...getState().serverData, ...serverData } });
    },
  setOpenModalOnMount:
    (openModalOnMount: State['openModalOnMount']): Action<State> =>
    ({ setState }) =>
      setState({ openModalOnMount }),
};

type Actions = typeof actions;

const Store = createStore<State, Actions>({
  initialState: {
    gender: undefined,
    age: undefined,
    heightCm: undefined,
    heightFeet: undefined,
    heightInch: undefined,
    heightTotalInches: undefined,
    heightUnit: Unit.CM,
    weightKg: undefined,
    weightLbs: undefined,
    weightUnit: Unit.KG,
    activityLevel: {
      index: 1,
      value: calculatorConsts.settings.activityLevels[ActivityLevel.sedentary],
      label: '',
    },
    accordingActivity: 0,
    bfi: {
      value: undefined,
      label: BfiLabel.Custom,
      min: 0,
      max: 0,
      custom: '',
    },
    primaryHealthGoal: {
      label: undefined,
      value: undefined,
    },
    recommendedDailyCarbsIntakeChoice: undefined,
    recommendedDailyProteinIntakeChoice: undefined,
    bmi: 0,
    bmr: undefined,
    proteinData: {
      min: 0,
      min_g_per_kg: 0,
      min_g_per_lb: 0,
      max: 0,
      max_g_per_kg: 0,
      max_g_per_lb: 0,
      chosen: 0,
      chosen_g_per_kg: 0,
      chosen_g_per_lb: 0,
      suggested: 0,
    },
    fatData: {
      chosen: calculatorConsts.settings.fat_suggested,
      chosen_g: 0,
      chosen_kcal: 0,
      min_g: 0,
      min_kcal: 0,
      maintenance_g: 0,
      maintenance_kcal: 0,
      max_g: 0,
      max_kcal: 0,
    },
    measureSystem: MeasureSystem.Metric,
    carbsData: {
      min: calculatorConsts.settings.carbs_min,
      max: calculatorConsts.settings.carbs_max,
      suggested: calculatorConsts.settings.carbs_suggested,
      chosen: calculatorConsts.settings.carbs_suggested,
      net: false,
    },
    completed: false,
    currentStep: 'gender',
    mode: 'calculator',
    serverData: undefined,
    openModalOnMount: false,
  },
  actions,
  name: STORE_NAME,
});

defaults.middlewares.add(
  storeSideEffectMiddleware(STORE_NAME, (state: State) => {
    localStorage.setItem(LocalStorageKey.MACROS_APP, state);
  })
);

const MacrosAppContainer: ContainerComponent<{
  initialState?: State;
}> = createContainer(Store, {
  onInit:
    () =>
    ({ setState, getState }, { initialState }) => {
      const persistedState = localStorage.getItem<State>(
        LocalStorageKey.MACROS_APP
      );

      setState({
        ...getState(),
        ...persistedState,
        ...(initialState ?? {}),
      });
    },
});

const useMacrosAppStore = createHook(Store);

export { useMacrosAppStore, MacrosAppContainer };
